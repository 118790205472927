<h2>{{editMode ? "Edit Mail Account" : "Create Mail Account"}}</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Username*</mat-label>
        <input #username matInput formControlName="username" name="username" type="text" maxlength="100"/>
        <mat-hint align="end">{{ username.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Domain*</mat-label>
        <mat-select formControlName="domain" name="domain">
          <mat-option *ngFor="let d of domains" [value]="d">
            {{ d }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Quota (MB)*</mat-label>
        <input matInput formControlName="quota" name="quota" type="number"/>
      </mat-form-field>
      <div>
        <mat-checkbox formControlName="sendonly" sendonly="quota">Send Only</mat-checkbox>
      </div>
      <div>
        <mat-checkbox color="primary" formControlName="enabled" sendonly="quota">Enabled</mat-checkbox>
      </div>
    </div>
  </form>
</mat-dialog-content>
<div *ngIf="accountExists" class="error-message">
  <til-message type="info" content="Account already exists" headline="Account duplicate" icon="cancel"></til-message>
</div>
<mat-dialog-actions align="end">
  <button (click)="cancel()" mat-button>cancel</button>
  <button [disabled]="formGroup.invalid" color="primary" (click)="save()" mat-raised-button>save</button>
</mat-dialog-actions>
