import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MatInputModule} from "@angular/material/input";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {MatButtonModule} from "@angular/material/button";
import {TiFrontendSharedModule} from "ti-frontend-shared";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {RouterModule} from "@angular/router";
import {MatIconModule} from "@angular/material/icon";
import {MatMenuModule} from "@angular/material/menu";
import {MatTableModule} from "@angular/material/table";
import {MatDialogModule} from "@angular/material/dialog";
import {MatChipsModule} from "@angular/material/chips";
import {MatAutocompleteModule} from "@angular/material/autocomplete";
import {MatSelectModule} from "@angular/material/select";
import {SubNavigationComponent} from './components/sub-navigation/sub-navigation.component';
import {EditUserInfoComponent} from './components/edit-user-info/edit-user-info.component';
import {InlineSpinnerComponent} from './components/inline-spinner/inline-spinner.component';
import {ConfirmationModalComponent} from "./modals/confirmation-modal/confirmation-modal.component";
import { InformationModalComponent } from './modals/information-modal/information-modal.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {ShowMailAccountComponent} from "./components/show-mail-account/show-mail-account.component";
import { SetMailAccountPasswordModalComponent } from './modals/set-mail-account-password-modal/set-mail-account-password-modal.component';

@NgModule({
  declarations: [SubNavigationComponent, EditUserInfoComponent, InlineSpinnerComponent, ConfirmationModalComponent, InformationModalComponent, ShowMailAccountComponent, SetMailAccountPasswordModalComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    TiFrontendSharedModule,
    RouterModule,
    MatButtonModule,
    MatToolbarModule,
    MatSidenavModule,
    MatInputModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule
  ], exports: [
    FormsModule,
    MatInputModule,
    HttpClientModule,
    ReactiveFormsModule,
    MatButtonModule,
    TiFrontendSharedModule,
    MatToolbarModule,
    MatSidenavModule,
    RouterModule,
    MatIconModule,
    MatMenuModule,
    MatTableModule,
    MatDialogModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatSelectModule,
    SubNavigationComponent,
    EditUserInfoComponent,
    InlineSpinnerComponent,
    MatCheckboxModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatPaginatorModule,
    ShowMailAccountComponent
  ]
})
export class SharedModule { }
