import { Component, OnInit } from '@angular/core';
import {ApplicationHelper, SpinnerService} from "ti-frontend-shared";
import {MailManagementService} from "../../services/mail-management.service";
import {AccountTO, UserTO} from "../../../shared/generated/transportObjects";
import {HttpErrorResponse} from "@angular/common/http";
import {MatTableDataSource} from "@angular/material/table";
import {ModalService} from "../../../core/services/modal.service";

@Component({
  selector: 'adm-mail-accounts-overview',
  templateUrl: './mail-accounts-overview.component.html',
  styleUrls: ['./mail-accounts-overview.component.scss']
})
export class MailAccountsOverviewComponent implements OnInit {

  public accounts: AccountTO[];
  public serverNotReachable: boolean = false;

  public tableDataSource: MatTableDataSource<AccountTO>;
  public visibleColumns: string[] = ["userName", "quota", "sendOnly", "aliases", "enabled"];

  constructor(
    private spinner: SpinnerService,
    private mailService: MailManagementService,
    private modalService: ModalService
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    this.mailService.findAllAccounts().subscribe(res => {
      this.accounts = res;
      this.sortAccounts();
      this.tableDataSource = new MatTableDataSource<AccountTO>(this.accounts);
      this.spinner.spin(false);
    }, (err: HttpErrorResponse) => {
      if (err.status === 502) {
        this.serverNotReachable = true;
      }
      this.spinner.spin(false);
    })
  }

  public openCreateAccountModal(): void {
    this.modalService.openCreateEditAccountModal().afterClosed().subscribe(res => {
      if (ApplicationHelper.isObjectDefined(res)) {
        this.accounts.push(res);
        this.sortAccounts();
        this.tableDataSource = new MatTableDataSource<AccountTO>(this.accounts);
      }
    });
  }

  private sortAccounts(): void {
    this.accounts = this.accounts.sort((a, b) => a.username.localeCompare(b.username))
  }

}
