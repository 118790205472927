import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {v4 as uuid} from "uuid";

@Component({
  selector: 'adm-set-mail-account-password-modal',
  templateUrl: './set-mail-account-password-modal.component.html',
  styleUrls: ['./set-mail-account-password-modal.component.scss']
})
export class SetMailAccountPasswordModalComponent implements OnInit {

  public password: string;

  constructor(
    public dialogRef: MatDialogRef<SetMailAccountPasswordModalComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: string
  ) { }

  ngOnInit(): void {
  }

  public generate(): void {
    this.password = uuid();
  }

}
