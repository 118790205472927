import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MyUserRoutingModule } from './my-user-routing.module';
import { UserDataOverviewComponent } from './user-data-overview/user-data-overview.component';
import {SharedModule} from "../shared/shared.module";
import { ChangePasswordComponent } from './user-data-overview/change-password/change-password.component';
import { ChangeUserInfoComponent } from './user-data-overview/change-user-info/change-user-info.component';
import { VerifyUserAccountComponent } from './verify-user-account/verify-user-account.component';
import { MyUserMailAccountsComponent } from './my-user-mail-accounts/my-user-mail-accounts.component';


@NgModule({
  declarations: [UserDataOverviewComponent, ChangePasswordComponent, ChangeUserInfoComponent, VerifyUserAccountComponent, MyUserMailAccountsComponent],
  imports: [
    CommonModule,
    MyUserRoutingModule,
    SharedModule
  ]
})
export class MyUserModule { }
