<h2>Set Mail-Account Password</h2>
<mat-dialog-content>
  <div>
    Set the account password for {{data}} here. Once set, the password cannot be displayed again.
  </div>
  <div class="input-area">
  <mat-form-field>
    <mat-label>Account Password</mat-label>
    <input #pw matInput [(ngModel)]="password" required name="userName" type="text" />
    <button *ngIf="!password" matSuffix mat-icon-button aria-label="Clear" (click)="generate()">
      <mat-icon color="accent">emoji_symbols</mat-icon>
    </button>
    <mat-hint align="end">{{ pw.value?.length || 0 }} characters</mat-hint>
  </mat-form-field>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="dialogRef.close(undefined)" mat-button>cancel</button>
  <button [disabled]="!password" (click)="dialogRef.close(password)" mat-raised-button>set password</button>
</mat-dialog-actions>


