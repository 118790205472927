import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {SideNavElement, SideNavService} from "ti-frontend-shared";
import {Event, Router, RouterEvent} from "@angular/router";

@Component({
  selector: 'adm-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent implements OnInit, OnDestroy {

  private routerSubscription: Subscription;
  public visibleNavElements: SideNavElement[] = [];

  private myUserNavElements: SideNavElement[] = [
    {icon: "accessibility_new", target: "my-user/overview", title: "Overview"},
    {icon: "contact_mail", target: "my-user/mail-accounts", title: "Mail Accounts"},
  ];

  private userManagementElements: SideNavElement[] = [
    {icon: "people", target: "user-management/overview", title: "Users"},
    {icon: "engineering", target: "user-management/roles", title: "Roles"},
    {icon: "mark_mail_read", target: "user-management/mails", title: "Mail"},
    {icon: "language", target: "user-management/domains", title: "Domains"},
  ];

  private serverManagementElements: SideNavElement[] = [
    {icon: "verified", target: "server-management/overview", title: "Clients"},
    {icon: "add_chart", target: "server-management/server", title: "Server"},
  ];

  private reportsElements: SideNavElement[] = [
    {icon: "saved_search", target: "reports/logs", title: "Logs"}
  ];

  constructor(
    private navService: SideNavService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.routerSubscription = this.router.events.subscribe((event: Event) => {
      if (event instanceof RouterEvent) {
        const url = (event as RouterEvent).url;
        if (url.startsWith("/my-user")) {
          this.visibleNavElements = this.myUserNavElements;
        }
        if (url.startsWith("/user-management")) {
          this.visibleNavElements = this.userManagementElements;
        }
        if (url.startsWith("/server-management")) {
          this.visibleNavElements = this.serverManagementElements;
        }
        if (url.startsWith("/reports")) {
          this.visibleNavElements = this.reportsElements;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.routerSubscription.unsubscribe();
  }

}
