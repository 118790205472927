import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {AuthServerRoutingModule} from './auth-server-routing.module';
import {SharedModule} from "../shared/shared.module";
import {AuthServerOverviewComponent} from './components/auth-server-overview/auth-server-overview.component';
import {ResourceServerManagementComponent} from './components/resource-server-management/resource-server-management.component';
import {EditCreateScopeModalComponent} from './modals/edit-create-scope-modal/edit-create-scope-modal.component';
import {AddResourceServerModalComponent} from "./modals/add-resource-server-modal/add-resource-server-modal.component";
import {EditCreateAuthClientModalComponent} from './modals/edit-create-auth-client-modal/edit-create-auth-client-modal.component';
import {SelectClientPermissionsComponent} from './components/select-client-permissions/select-client-permissions.component';
import {ResetClientSecretModalComponent} from './modals/reset-client-secret-modal/reset-client-secret-modal.component';


@NgModule({
  declarations: [AuthServerOverviewComponent, ResourceServerManagementComponent, EditCreateScopeModalComponent, AddResourceServerModalComponent, EditCreateAuthClientModalComponent, SelectClientPermissionsComponent, ResetClientSecretModalComponent],
  imports: [
    CommonModule,
    AuthServerRoutingModule,
    SharedModule
  ]
})
export class AuthServerModule { }
