<div class="edit-user-info-container">

  <div class="form-container">

    <form novalidate [formGroup]="formGroup">

      <mat-form-field>
        <mat-label>Username*</mat-label>
        <input #userName matInput formControlName="userName" name="userName" type="text" maxlength="20" />
        <mat-hint align="end">{{ userName.value?.length || 0 }}/20</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Firstname*</mat-label>
        <input #firstName matInput formControlName="firstName" name="firstName" type="text" maxlength="80" />
        <mat-hint align="end">{{ firstName.value?.length || 0 }}/80</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Lastname*</mat-label>
        <input #lastName matInput formControlName="lastName" name="lastName" type="text" maxlength="80" />
        <mat-hint align="end">{{ lastName.value?.length || 0 }}/80</mat-hint>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Roles</mat-label>
        <mat-select formControlName="roles" multiple>
          <mat-option *ngFor="let role of availableRoles" [value]="role">{{role}}</mat-option>
        </mat-select>
      </mat-form-field>
    </form>

  </div>

  <div class="ti controls">
    <button (click)="cancel.emit()" mat-raised-button>cancel</button>
    <button color="primary" [disabled]="formGroup.invalid" (click)="save()" mat-raised-button>save</button>
  </div>

</div>
