import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ApiEndpoints} from "../../shared/util/api-endpoints";
import {Observable} from "rxjs";
import {User} from "../../shared/model/user";

@Injectable({
  providedIn: 'root'
})
export class InitializationService {

  constructor(
    private http: HttpClient,
    @Inject('ADM_SERVER_URL') private adminServerUrl: string,
  ) { }

  public isServerInitialized(): Observable<boolean> {
    const url: string = this.adminServerUrl + ApiEndpoints.isServerInitialized;
    return this.http.get<boolean>(url);
  }

  public initSu(user: User): Observable<Object> {
    const url: string = this.adminServerUrl + ApiEndpoints.initSu;
    return this.http.post(url, user);
  }

}
