<h2>Really reset client secret</h2>
<mat-dialog-content>
  <p>{{'Do you really want reset the client secret for ' + client.clientName + '. All recent configurations will break. You can set your own secret here or leave the input empty and our server creates a new one for you.'}}</p>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Secret (Optional)</mat-label>
        <input matInput formControlName="secret" name="secret" type="text" maxlength="100"/>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">No</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Yes - I know what I'm doing</button>
  </div>
</mat-dialog-content>
