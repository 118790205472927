import {Component, OnDestroy, OnInit} from '@angular/core';
import {AuthenticationService, SideNavService, SpinnerService} from "ti-frontend-shared";
import {Subscription} from "rxjs";
import {Router} from "@angular/router";
import {AuthorizationHelper} from "./shared/util/authorization-helper";

@Component({
  selector: 'adm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'admin-frontend';

  public loading = false;
  public sideNavOpen: boolean = true;
  private subscriptions: Subscription[] = [];

  ngOnInit(): void {
    this.subscriptions.push(this.spinnerService.observable().subscribe((value: boolean) => this.loading = value));
    this.subscriptions.push(this.sideNavService.stateObservable.subscribe((value: boolean) => this.sideNavOpen = value));
  }

  ngOnDestroy() {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  constructor(
    private router: Router,
    private spinnerService: SpinnerService,
    private sideNavService: SideNavService,
    private authenticationService: AuthenticationService
  ) {
  }

  public get isAdmin(): boolean {
    return AuthorizationHelper.isUserInRole(this.authenticationService.currentUser, "admin");
  }

  public get fullUserName(): string {
    return this.authenticationService.currentUser ? (this.authenticationService.currentUser.firstName || "") + " " + (this.authenticationService.currentUser.lastName || "") : "";
  }

  public get preLogin(): boolean {
    return this.router.url.startsWith("/login") || this.router.url.startsWith("/application/init") || !this.authenticationService.isAuthenticated()
  }

  public logout(): void {
    this.authenticationService.logout();
    this.router.navigateByUrl("/login");
  }


}
