import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AccountTO, AliasTO} from "../../../shared/generated/transportObjects";
import {MailManagementService} from "../../services/mail-management.service";
import {SpinnerService} from "ti-frontend-shared";
import {ApplicationHelper} from "../../../shared/util/application-helper";

@Component({
  selector: 'adm-create-edit-mailbox-modal',
  templateUrl: './create-edit-mailbox-modal.component.html',
  styleUrls: ['./create-edit-mailbox-modal.component.scss']
})
export class CreateEditMailboxModalComponent implements OnInit {

  public formGroup: FormGroup;
  public readonly editMode: boolean;
  public domains: string[] = [];
  public account: AccountTO;

  constructor(
    public dialogRef: MatDialogRef<CreateEditMailboxModalComponent, AliasTO>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private ms: MailManagementService,
    private spinner: SpinnerService
  ) {
    this.editMode = ApplicationHelper.isObjectDefined(this.data.mailbox);
    this.account = this.data.account;
  }

  ngOnInit(): void {
    this.ms.findAllDomains().subscribe(res => res.map(d => d.domain).forEach(d => this.domains.push(d)));
    this.initFormGroup();
  }

  public save(): void {
    if (this.formGroup.valid) {
      this.spinner.spin(true);
      if (this.editMode) {
        this.ms.updateMailbox(this.formGroup.value).subscribe(res => {
          this.dialogRef.close(res);
          this.spinner.spin(false);
        }, () => this.spinner.spin(false));
      } else {
        this.ms.createMailbox(this.formGroup.value).subscribe(res => {
          this.dialogRef.close(res);
          this.spinner.spin(false);
        }, () => this.spinner.spin(false));
      }
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(""),
      version: this.fb.control(""),
      sourceUsername: this.fb.control("", Validators.required),
      sourceDomain: this.fb.control("", Validators.required),
      destinationUsername: this.fb.control(this.account.username, Validators.required),
      destinationDomain: this.fb.control(this.account.domain, Validators.required),
      enabled: this.fb.control(true, Validators.required)
    });
    if (ApplicationHelper.isObjectDefined(this.data.mailbox)) {
      this.formGroup.patchValue(this.data.mailbox);
    }
  }

}
