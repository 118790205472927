<h2>Add system domain</h2>
<mat-dialog-content>
    <div>
      <mat-form-field>
        <mat-label>Domain</mat-label>
        <input #d matInput required [pattern]="domainPattern" [(ngModel)]="domain" name="username" type="text" maxlength="100"/>
        <mat-hint align="end">{{ d.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
    </div>
  <til-message *ngIf="exists" type="info" headline="Cannot create domain" content="Domain already exists." icon="error"></til-message>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="cancel()" mat-button>cancel</button>
  <button [disabled]="!validDomain()" color="primary" (click)="save()" mat-raised-button>save</button>
</mat-dialog-actions>
