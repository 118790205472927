import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot} from "@angular/router";
import {MailManagementService} from "../../services/mail-management.service";
import {SpinnerService} from "ti-frontend-shared";
import {AccountTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'adm-administrate-mail-account',
  templateUrl: './administrate-mail-account.component.html',
  styleUrls: ['./administrate-mail-account.component.scss']
})
export class AdministrateMailAccountComponent implements OnInit {

  public account: AccountTO;

  constructor(
    private activatedRoute: ActivatedRoute,
    private mailService: MailManagementService,
    private spinner: SpinnerService
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    const accountId: number = this.activatedRoute.snapshot.params['accountId'];
    this.mailService.findUserAccount(accountId).subscribe(res => {
      this.account = res;
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }

}
