<div *ngIf="!selectingPermissions; else perm" class="client-overview">
  <div class="header">
    <h1>OAuth Clients</h1>
    <div class="flex-spacer"></div>
    <div *ngIf="!authServerUnreachable && clients" class="add-client-button animate__animated animate__fadeIn">
      <button (click)="openCreateEditClientModal()" mat-fab>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>
  <div class="client-content">
    <div class="scrollable client-list">
      <adm-inline-spinner *ngIf="!clients && !authServerUnreachable"></adm-inline-spinner>
      <til-flex-icon-message *ngIf="authServerUnreachable" color="accent" icon="cloud_off">Server not reachable</til-flex-icon-message>

      <div *ngIf="clients && clients.length > 0" class="client-list">
        <div (click)="selectClient(c)" *ngFor="let c of clients" class="client" [class.selected]="clientSelected(c)">
          <div>{{c.clientId}}</div>
          <div>{{c.clientName}}</div>
        </div>
      </div>

    </div>

    <div class="scrollable client-details">

      <adm-inline-spinner *ngIf="!clients && !authServerUnreachable"></adm-inline-spinner>
      <til-flex-icon-message *ngIf="authServerUnreachable" color="accent" icon="cloud_off">Server not reachable</til-flex-icon-message>

      <div *ngIf="clients && selectedClient">
        <div class="content">

          <div class="details-header">
          <h2>{{selectedClient.clientName}}</h2>
            <div class="flex-spacer"></div>
            <span *ngIf="su" (click)="openCreateEditClientModal(selectedClient)" class="material-icons">edit</span>
          </div>

          <p>Client-ID: {{selectedClient.clientId}}</p>
          <p>Tokens valid for: <b>{{selectedClient.validityInSeconds}}</b> seconds</p>

          <div class="permissions">
            <h3>{{hasAnyPermission() ? 'Granted Permissions:' : 'No granted permission'}}</h3>
            <div *ngFor="let s of grantedResourceServer; let last = last" class="permission">
              <div>{{s.serverName}} ({{s.serverIdentifier}}):</div>
              <mat-chip-list *ngIf="filterGrantedPermissions(s.scopes).length > 0; else noscopes">
                <mat-chip *ngFor="let p of filterGrantedPermissions(s.scopes)" selected [color]="'accent'">{{p.scope}}</mat-chip>
              </mat-chip-list>
              <ng-template #noscopes>
                <i>no scopes</i>
              </ng-template>
              <div *ngIf="!last" class="permission-separator">
                <div></div>
              </div>
            </div>
          </div>

        </div>
        <div class="flex-spacer"></div>
        <div class="actions">
          <button *ngIf="su" (click)="checkDeleteClient()" mat-button color="warn">Delete Client</button>
          <div class="flex-spacer"></div>
          <button *ngIf="su" (click)="checkResetSecret()" mat-flat-button color="warn">Reset Secret</button>
          <button mat-flat-button color="accent" (click)="selectingPermissions = true">Edit Permissions</button>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #perm>
  <adm-select-client-permissions *ngIf="selectedClient" (canceled)="selectingPermissions = false" (updated)="selectingPermissions = false; updateClient($event)" [client]="selectedClient"></adm-select-client-permissions>
</ng-template>
