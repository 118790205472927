import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClientTO, ResourceServerTO, ScopeTO} from "../../../shared/generated/transportObjects";
import {AuthServerService} from "../../services/auth-server.service";
import {SpinnerService} from "ti-frontend-shared";
import {ApplicationHelper} from "../../../shared/util/application-helper";

@Component({
  selector: 'adm-select-client-permissions',
  templateUrl: './select-client-permissions.component.html',
  styleUrls: ['./select-client-permissions.component.scss']
})
export class SelectClientPermissionsComponent implements OnInit {

  @Input()
  public client: ClientTO;

  @Output()
  public canceled: EventEmitter<void> = new EventEmitter<void>();

  @Output()
  public updated: EventEmitter<ClientTO> = new EventEmitter<ClientTO>();

  public availableResourceServer: ResourceServerTO[];
  public grantedResourceServer: ResourceServerTO[] = [];
  public grantedScopes: ScopeTO[] = [];

  constructor(
    private as: AuthServerService,
    private spinner: SpinnerService
  ) {

  }

  ngOnInit(): void {
    this.as.readAllResourceServer().subscribe(res => {
      this.availableResourceServer = res;
    });
    this.extractGrantedPermissions();
  }

  public hasServerPermission(server: ResourceServerTO): boolean {
    return ApplicationHelper.isObjectDefined(this.grantedResourceServer.find(value => value.id === server.id));
  }

  public hasScopePermission(scope: ScopeTO): boolean {
    return ApplicationHelper.isObjectDefined(this.grantedScopes.find(value => value.id === scope.id));
  }

  public grantOrRevokeServerPermission(server: ResourceServerTO): void {
    if (this.hasServerPermission(server)) {
      this.grantedResourceServer = this.grantedResourceServer.filter(value => value.id !== server.id);
      server.scopes.forEach(s => {
        this.grantedScopes = this.grantedScopes.filter(value => value.id !== s.id);
      })
    } else {
      this.grantedResourceServer.push(server);
    }
  }

  public grantOrRevokeScope(server: ResourceServerTO, scope: ScopeTO): void {
    if (this.hasServerPermission(server)) {
      if (this.hasScopePermission(scope)) {
        this.grantedScopes = this.grantedScopes.filter(value => value.id !== scope.id);
      } else {
        this.grantedScopes.push(scope);
      }
    }
  }

  public cancel(): void {
    this.canceled.emit();
  }

  public save(): void {
    this.spinner.spin(true);
    this.client.grantedResourceServers = this.grantedResourceServer;
    this.client.grantedScopes = this.grantedScopes;
    this.as.updateClientPermissions(this.client).subscribe(res => {
      this.updated.emit(res);
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }

  private extractGrantedPermissions(): void {
    if (this.client.grantedResourceServers && this.client.grantedResourceServers.length > 0) {
      this.client.grantedResourceServers.forEach(rs => {
        this.grantedResourceServer.push(rs);
        rs.scopes.forEach(s => this.grantedScopes.push(s));
      })
    } else {
      this.grantedResourceServer = [];
      this.grantedScopes = [];
    }
  }

}
