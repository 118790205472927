import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MatInput} from "@angular/material/input";
import {User} from "../../../shared/model/user";
import {InitializationService} from "../../services/initialization.service";
import {Router} from "@angular/router";

@Component({
  selector: 'adm-initialization',
  templateUrl: './initialization.component.html',
  styleUrls: ['./initialization.component.scss']
})
export class InitializationComponent implements OnInit {

  public initForm: FormGroup;
  public passwordMissmatch: boolean;
  private processing: boolean;

  @ViewChild("fn", {static: true})
  private fn: MatInput;

  constructor(
    private fb: FormBuilder,
    private initService: InitializationService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.initService.isServerInitialized().subscribe(
      (initialized: boolean) => {
        if (initialized) {
          this.router.navigateByUrl("login");
        }
      }
    )
    this.initForm = this.fb.group({
      userName: this.fb.control("", Validators.required),
      password: this.fb.control("", Validators.required),
      passwordR: this.fb.control("", Validators.required)
    });
    setTimeout(() => {
      this.fn.focus();
    }, 500);

  }

  public init(): void {
    this.passwordMissmatch = false;
    if (this.initForm.valid && !this.processing) {
      this.processing = true;
      if (this.initForm.get('password').value === this.initForm.get('passwordR').value) {
        const user: User = this.initForm.value;
        this.initService.initSu(user).subscribe(() => this.router.navigateByUrl("login"));
      } else {
        this.passwordMissmatch = true;
        this.processing = false;
      }
    }
  }
}
