import { Component, OnInit } from '@angular/core';
import {MailManagementService} from "../../services/mail-management.service";
import {DomainTO} from "../../../shared/generated/transportObjects";
import {SpinnerService} from "ti-frontend-shared";
import {ModalService} from "../../../core/services/modal.service";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {HttpErrorResponse} from "@angular/common/http";
import {InformationModalContext} from "../../../shared/model/information-modal-context";

@Component({
  selector: 'adm-domain-management',
  templateUrl: './domain-management.component.html',
  styleUrls: ['./domain-management.component.scss']
})
export class DomainManagementComponent implements OnInit {

  public domains: DomainTO[];

  constructor(
    private ms: MailManagementService,
    private spinner: SpinnerService,
    private modalService: ModalService
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    this.ms.findAllDomains().subscribe(res => {
      this.domains = res;
      this.spinner.spin(false);
    })
  }

  public openAddDomainModal(): void {
    this.modalService.openAddDomainModal().afterClosed().subscribe(res => {
      if (res) {
        this.domains.push(res);
      }
    });
  }

  public openCheckDeleteModal(domain: DomainTO): void {
    const ctx: ConfirmationModalContext = {
      severity: "warn", approveButtonLabel: "Delete Domain", content: "Really delete Domain " + domain.domain + "?", headline: "Delete Domain"
    };
    this.modalService.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.deleteDomain(domain);
      }
    })
  }

  private deleteDomain(domain: DomainTO): void {
    this.spinner.spin(true);
    this.ms.deleteDomain(domain.id).subscribe(() => {
      this.domains = this.domains.filter(d => d.id !== domain.id);
      this.spinner.spin(false);
    }, (err: HttpErrorResponse) => {
      this.spinner.spin(false);
      if (err.status === 403) {
        const ctx: InformationModalContext = {
          severity: "warn", headline: "Cannot delete domain", content: "Domain still in use"
        };
        this.modalService.openInformationModal(ctx);
      }
    });
  }





}
