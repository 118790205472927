<h2>Create Resource Server</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Server Name*</mat-label>
        <input #sn matInput formControlName="serverName" name="serverName" type="text" maxlength="100"/>
        <mat-hint align="end">{{ sn.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Server Identifier*</mat-label>
        <input #si matInput formControlName="serverIdentifier" name="serverIdentifier" type="text" maxlength="100"/>
        <mat-hint align="end">{{ si.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Resource Server Description</mat-label>
        <textarea
          matInput
          maxlength="200"
          #desc
          formControlName="serverDescription"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
        <mat-hint align="end">{{ desc.value?.length || 0 }}/200</mat-hint>
      </mat-form-field>

      <mat-form-field *ngIf="!editMode" class="scope-list">
        <mat-label>Server Scopes</mat-label>
        <mat-chip-list #chipList aria-label="Scope selection">
          <mat-chip
            *ngFor="let s of scopes"
            [selectable]="false"
            [removable]="true"
            (removed)="removeScope(s)">
            {{s}}
            <mat-icon matChipRemove>cancel</mat-icon>
          </mat-chip>
          <input
            placeholder="Add Scope..."
            #scopeInput
            [matChipInputFor]="chipList"
            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
            (matChipInputTokenEnd)="addScope($event)">
        </mat-chip-list>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

