import { Component, OnInit } from '@angular/core';
import {AccountTO} from "../../shared/generated/transportObjects";
import {MyUserService} from "../services/my-user.service";
import {SpinnerService} from "ti-frontend-shared";

@Component({
  selector: 'adm-my-user-mail-accounts',
  templateUrl: './my-user-mail-accounts.component.html',
  styleUrls: ['./my-user-mail-accounts.component.scss']
})
export class MyUserMailAccountsComponent implements OnInit {

  public account: AccountTO;

  constructor(
    private us: MyUserService,
    private spinner: SpinnerService
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    this.us.loadUserMailAccount().subscribe(res => {
      this.account = res;
      this.spinner.spin(false);
    }, () => this.spinner.spin(false))
  }

}
