import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import {ModalService} from './modal.service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {InformationModalContext} from '../../shared/model/information-modal-context';
import {ApplicationHelper} from "ti-frontend-shared";
import {ModalSeverity} from "../../shared/generated/transportObjects";

@Injectable({
  providedIn: 'root'
})
export class ErrorResponseInterceptorService implements HttpInterceptor {

  constructor(
    private ms: ModalService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(res => res,
        err => {
          if (err instanceof HttpErrorResponse && err.status === 418) {
            const severity: ModalSeverity = err.error.modalSeverity;
            const severityString = ApplicationHelper.isObjectDefined(severity) && severity === "INFO" ? "accent" : "warn";
            const context: InformationModalContext = {
              headline: err.error.headline,
              content: err.error.content,
              severity: severityString
            };
            this.ms.openInformationModal(context);
          }
        }));
  }

}
