import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {UserTO} from "../../../shared/generated/transportObjects";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatAutocompleteSelectedEvent} from "@angular/material/autocomplete";
import {UserManagementService} from "../../services/user-management.service";

@Component({
  selector: 'adm-create-user-modal',
  templateUrl: './create-user-modal.component.html',
  styleUrls: ['./create-user-modal.component.scss']
})
export class CreateUserModalComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<CreateUserModalComponent, UserTO>
  ) {
  }

  ngOnInit(): void {
  }

  public saveUser(user: UserTO): void {
    this.dialogRef.close(user);
  }

}
