import {Component, OnInit} from '@angular/core';
import {LogsService} from "../../services/logs.service";
import {LogFilterTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'adm-application-logs',
  templateUrl: './application-logs.component.html',
  styleUrls: ['./application-logs.component.scss']
})
export class ApplicationLogsComponent implements OnInit {

  constructor(
    private ls: LogsService
  ) { }

  ngOnInit(): void {
  }

  public get loader() {
    return (filter: LogFilterTO) => this.ls.loadAllLogs(filter);
  }

}
