import {Component, OnInit} from '@angular/core';
import {MatTableDataSource} from "@angular/material/table";
import {RoleTO} from "../../../shared/generated/transportObjects";
import {UserManagementService} from "../../services/user-management.service";
import {AuthenticationService, SpinnerService} from "ti-frontend-shared";
import {ModalService} from "../../../core/services/modal.service";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {AuthorizationHelper} from "../../../shared/util/authorization-helper";
import {ApplicationRoles} from "../../../shared/util/application-roles";

@Component({
  selector: 'adm-roles-overview',
  templateUrl: './roles-overview.component.html',
  styleUrls: ['./roles-overview.component.scss']
})
export class RolesOverviewComponent implements OnInit {

  public tableDataSource: MatTableDataSource<RoleTO>;
  public visibleColumns: string[] = ["roleName", "roleDescription", "actions"];
  public readonly isSu: boolean;


  constructor(
    private us: UserManagementService,
    private spinner: SpinnerService,
    private ms: ModalService,
    private as: AuthenticationService
  ) {
    this.spinner.spin(true);
    this.isSu = AuthorizationHelper.isUserInRole(this.as.currentUser, ApplicationRoles.SU);
  }

  ngOnInit(): void {
    this.us.loadAvailableRoles().subscribe(res => {
      this.tableDataSource = new MatTableDataSource<RoleTO>(res);
      this.sortData();
      this.spinner.spin(false);
    });
  }

  public openCreateRoleModal(): void {
    this.ms.openCreateEditRoleModal().afterClosed().subscribe(role => {
      if (role) {
        this.tableDataSource.data.push(role);
        this.sortData();
      }
    });
  }

  public openConfirmDeleteModal(role: RoleTO): void {
    const ctx: ConfirmationModalContext = {
      headline: "Really delete?", content: "Do you really want to delete role " + role.roleName + "?", severity: "warn", approveButtonLabel: "delete"
    };
    this.ms.openConfirmationModal(ctx).afterClosed().subscribe(() => {
      this.deleteRole(role);
    })

  }

  private deleteRole(role: RoleTO): void {
    this.spinner.spin(true);
    this.us.deleteRole(role.id).subscribe(() => {
      const tData = this.tableDataSource.data.filter(value => value.id !== role.id);
      this.tableDataSource = new MatTableDataSource<RoleTO>(tData);
      this.sortData();
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }

  public select(role: RoleTO): void {
    this.ms.openCreateEditRoleModal(role).afterClosed().subscribe(role => {
      if (role) {
        const tData = this.tableDataSource.data.filter(value => value.id !== role.id);
        tData.push(role);
        this.tableDataSource.data = tData;
        this.sortData();
      }
    });
  }

  public sortData(): void {
    const data: RoleTO[] = this.tableDataSource.data;
    this.tableDataSource = new MatTableDataSource<RoleTO>(data.sort((a, b) => a.roleName < b.roleName ? -1 : 1));
  }



}
