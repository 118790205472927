import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ScopeTO} from "../../../shared/generated/transportObjects";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {ValidationHelper} from "../../../shared/util/validation-helper";

@Component({
  selector: 'adm-edit-create-auth-client-modal',
  templateUrl: './edit-create-auth-client-modal.component.html',
  styleUrls: ['./edit-create-auth-client-modal.component.scss']
})
export class EditCreateAuthClientModalComponent implements OnInit {

  public formGroup: FormGroup
  public readonly editMode: boolean;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<EditCreateAuthClientModalComponent, ScopeTO>,
    @Inject(MAT_DIALOG_DATA) public data: ScopeTO
  ) {
    this.editMode = ApplicationHelper.isObjectDefined(data);
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.editMode) {
      this.formGroup.patchValue(this.data);
    }
  }

  public save(): void {
    if (this.formGroup.valid) {
      this.dialogRef.close(this.formGroup.value);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(undefined),
      version: this.fb.control(undefined),
      clientId: this.fb.control("", [Validators.required, ValidationHelper.idPatternValidator]),
      clientName: this.fb.control("", Validators.required),
      validityInSeconds: this.fb.control("", Validators.required)
    });
  }

}
