<div *ngIf="domainInformation" class="show-domain-info-container">

  <h1>{{domainInformation.domainName}}</h1>

  <h3>Accounts</h3>

  <div *ngIf="domainInformation?.usingAccounts?.length > 0">

    <div class="artifacts animate__animated animate__fadeIn">
      <div [routerLink]="'/user-management/mails/account/' + account.id" *ngFor="let account of domainInformation.usingAccounts; let i = index" class="account using-artifact">
        <div class="status"><span class="material-icons">{{account.enabled ? 'check' : 'remove'}}</span> {{account.enabled ? 'Enabled' : 'Disabled'}}</div>
        <div class="name">{{account.username}}</div>
        <div class="domain">@{{domainInformation.domainName}}</div>
      </div>
    </div>

  </div>

  <h3>Mailboxes</h3>

  <div *ngIf="domainInformation?.usingAliases?.length > 0">

    <div class="artifacts animate__animated animate__fadeIn">
      <div *ngFor="let alias of domainInformation.usingAliases; let i = index" class="using-artifact">
        <div class="status"><span class="material-icons">{{alias.enabled ? 'check' : 'remove'}}</span> {{alias.enabled ? 'Enabled' : 'Disabled'}}</div>
        <div class="name">{{alias.destinationUsername}}</div>
        <div class="domain">@{{alias.destinationDomain}}</div>
      </div>
    </div>

  </div>

</div>
<adm-sub-navigation target="/user-management/domains">all domains</adm-sub-navigation>
