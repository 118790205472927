import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {UserManagementRoutingModule} from './user-management-routing.module';
import {SharedModule} from "../shared/shared.module";
import {UserOverviewComponent} from './components/user-overview/user-overview.component';
import {UserListComponent} from './components/user-list/user-list.component';
import {ShowEditUserComponent} from './components/show-edit-user/show-edit-user.component';
import {RolesOverviewComponent} from './components/roles-overview/roles-overview.component';
import {CreateEditRoleModalComponent} from "./modals/create-edit-role-modal/create-edit-role-modal.component";
import {CreateUserModalComponent} from "./modals/create-user-modal/create-user-modal.component";
import { MailAccountsOverviewComponent } from './components/mail-accounts-overview/mail-accounts-overview.component';
import { AdministrateMailAccountComponent } from './components/administrate-mail-account/administrate-mail-account.component';
import { CreateEditMailAccountModalComponent } from './modals/create-edit-mail-account-modal/create-edit-mail-account-modal.component';
import { CreateEditMailboxModalComponent } from './modals/create-edit-mailbox-modal/create-edit-mailbox-modal.component';
import { DomainManagementComponent } from './components/domain-management/domain-management.component';
import { CreateDomainModalComponent } from './modals/create-domain-modal/create-domain-modal.component';
import { ShowDomainComponent } from './components/show-domain/show-domain.component';


@NgModule({
  declarations: [UserOverviewComponent, UserListComponent, ShowEditUserComponent, RolesOverviewComponent, CreateEditRoleModalComponent, CreateUserModalComponent, MailAccountsOverviewComponent, AdministrateMailAccountComponent, CreateEditMailAccountModalComponent, CreateEditMailboxModalComponent, DomainManagementComponent, CreateDomainModalComponent, ShowDomainComponent],
  imports: [
    CommonModule,
    UserManagementRoutingModule,
    SharedModule
  ]
})
export class UserManagementModule { }
