import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {ResourceServerTO, ScopeTO} from "../../../shared/generated/transportObjects";
import {MatChipInputEvent} from "@angular/material/chips";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {ValidationHelper} from "../../../shared/util/validation-helper";

@Component({
  selector: 'adm-add-resource-server-modal',
  templateUrl: './add-resource-server-modal.component.html',
  styleUrls: ['./add-resource-server-modal.component.scss']
})
export class AddResourceServerModalComponent implements OnInit {

  public scopes: string[] = [];
  public formGroup: FormGroup
  public separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('scopeInput') fruitInput: ElementRef<HTMLInputElement>;
  public readonly editMode: boolean;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<AddResourceServerModalComponent, ResourceServerTO>,
    @Inject(MAT_DIALOG_DATA) public data: ResourceServerTO
  ) {
    this.editMode = ApplicationHelper.isObjectDefined(data);
  }

  ngOnInit(): void {
    this.initFormGroup();
    if (this.editMode) {
      this.formGroup.patchValue(this.data);
    }
  }

  public removeScope(scope: string): void {
    this.scopes = this.scopes.filter(value => value !== scope);
  }

  public addScope(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if ((value || "").trim()) {
      this.scopes.push(value.trim());
    }
    if (input) {
      input.value = "";
    }
  }

  public save(): void {
    if (this.formGroup.valid) {
      const server: ResourceServerTO = this.formGroup.value;
      if (!this.editMode) {
        const scopes: ScopeTO[] = [];
        this.scopes.forEach(s => scopes.push({scope: s.trim().replace(" ", "_")}));
        server.scopes = scopes;
      }
      this.dialogRef.close(server);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(undefined),
      version: this.fb.control(undefined),
      serverName: this.fb.control("", Validators.required),
      serverIdentifier: this.fb.control("", [Validators.required, ValidationHelper.idPatternValidator]),
      serverDescription: this.fb.control("")
    });
  }

}
