import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SpinnerService} from "ti-frontend-shared";
import {MyUserService} from "../../services/my-user.service";
import {MatInput} from "@angular/material/input";
import {User} from "../../../shared/model/user";
import {UserTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'adm-change-user-info',
  templateUrl: './change-user-info.component.html',
  styleUrls: ['./change-user-info.component.scss']
})
export class ChangeUserInfoComponent implements OnInit {

  @Output()
  public userInfoChanged: EventEmitter<UserTO> = new EventEmitter<UserTO>();

  @Output()
  public cancel: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  public userInfo: any;

  @ViewChild("fn", {static: true})
  private fn: MatInput;

  public errorChangingInfo: boolean = false;
  private processing: boolean;
  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private userService: MyUserService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    setTimeout(() => {
      this.fn.focus();
    }, 500);
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      firstName: this.fb.control(this.userInfo.firstName || "", Validators.required),
      lastName: this.fb.control(this.userInfo.lastName || "", Validators.required)
    });
  }

  public changeInfo(): void {
    if (this.formGroup.valid && !this.processing) {
      this.processing = true;
        this.spinner.spin(true);
        this.userService.changeUserInfo(this.formGroup.get("firstName").value, this.formGroup.get("lastName").value).subscribe(
          (user: UserTO) => {
            this.processing = false;
            this.spinner.spin(false);
            this.userInfoChanged.emit(user);
          }, () => {
            this.processing = false;
            this.spinner.spin(false);
            this.errorChangingInfo = true;
          }
        )
    }
  }

}
