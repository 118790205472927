import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {UserTO} from "../../../shared/generated/transportObjects";
import {MatTableDataSource} from "@angular/material/table";
import {MatPaginator} from "@angular/material/paginator";

@Component({
  selector: 'adm-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit, AfterViewInit {

  @Output()
  userSelected: EventEmitter<UserTO> = new EventEmitter<UserTO>();

  @Input()
  users: UserTO[] = [];

  @Input()
  public visibleColumns: string[] = ["userName", "firstName", "lastName", "empNr", "verified", "roles", "locked"];

  @ViewChild(MatPaginator)
  public paginator: MatPaginator;

  public tableDataSource: MatTableDataSource<UserTO>;

  constructor() { }

  ngOnInit(): void {
    this.tableDataSource = new MatTableDataSource<UserTO>(this.users);
  }

  ngAfterViewInit(): void {
    this.tableDataSource.paginator = this.paginator;
  }

  public updateTable(): void {
    setTimeout(() => {
      this.tableDataSource = new MatTableDataSource<UserTO>(this.users);
      this.tableDataSource.paginator = this.paginator;
    });
  }


}
