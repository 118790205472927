<div class="user-overview-container">
  <div class="header">
  <h1>treeIT Users</h1>
    <div class="flex-spacer"></div>
    <div (click)="openCreateUserModal()" class="add-user-button">
      <button mat-fab><mat-icon>add</mat-icon></button>
    </div>
  </div>
  <div *ngIf="users">
    <adm-user-list (userSelected)="navigateToUserDetailPage($event)" [users]="users"></adm-user-list>
  </div>
</div>
