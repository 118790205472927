<div *ngIf="users && users.length > 0; else nousers" class="user-list-container">

  <table mat-table [dataSource]="tableDataSource">
    <mat-text-column name="userName" headerText="Username"></mat-text-column>
    <mat-text-column name="firstName" headerText="Firstname"></mat-text-column>
    <mat-text-column name="lastName" headerText="Lastname"></mat-text-column>
    <mat-text-column name="empNr" headerText="Emp. No."></mat-text-column>
    <ng-container matColumnDef="verified">
      <th mat-header-cell *matHeaderCellDef>Verified</th>
      <td mat-cell *matCellDef="let user">
        <span>{{ user.verified | date }}</span>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="roles">
      <th mat-header-cell *matHeaderCellDef>Roles</th>
      <td mat-cell *matCellDef="let user">
        <mat-chip-list>
          <mat-chip color="accent" *ngFor="let role of user.roles">{{role}}</mat-chip>
        </mat-chip-list>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <ng-container matColumnDef="locked">
      <th mat-header-cell *matHeaderCellDef>Enabled</th>
      <td mat-cell *matCellDef="let user">
        <mat-icon *ngIf="!user.locked">done</mat-icon>
        <mat-icon class="disabled-icon" *ngIf="user.locked">remove</mat-icon>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
    <tr class="selectable-row" (click)="userSelected.emit(user)" mat-row *matRowDef="let user; columns: visibleColumns"></tr>
  </table>
</div>

<ng-template #nousers>
  <div class="no-users">
    <span class="material-icons">supervisor_account</span>
    <div>No users available</div>
  </div>
</ng-template>
