<h2>Edit Scope</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Scope Name*</mat-label>
        <input #scope matInput formControlName="scope" name="scope" type="text" maxlength="100"/>
        <mat-hint align="end">{{ scope.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Scope Description</mat-label>
        <textarea
          matInput
          maxlength="200"
          #desc
          formControlName="scopeDescription"
          cdkTextareaAutosize
          #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="5"
        ></textarea>
        <mat-hint align="end">{{ desc.value?.length || 0 }}/200</mat-hint>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

