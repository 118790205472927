import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InitializationComponent} from './components/initialization/initialization.component';
import {WelcomeComponent} from './components/welcome/welcome.component';
import {SharedModule} from "../shared/shared.module";
import {LoginComponent} from './components/login/login.component';
import {SideNavComponent} from './components/side-nav/side-nav.component';
import {TokenInterceptorService} from "./services/token-interceptor.service";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {ErrorResponseInterceptorService} from "./services/error-response-interceptor.service";


@NgModule({
  declarations: [InitializationComponent, WelcomeComponent, LoginComponent, SideNavComponent],
  exports: [
    SideNavComponent
  ],
  imports: [
    CommonModule, SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorResponseInterceptorService,
      multi: true
    }
  ]
})
export class CoreModule {
}
