import {Component, OnInit, ViewChild} from '@angular/core';
import {UserManagementService} from "../../services/user-management.service";
import {UserTO} from "../../../shared/generated/transportObjects";
import {ModalService} from "../../../core/services/modal.service";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {SpinnerService} from "ti-frontend-shared";
import {UserListComponent} from "../user-list/user-list.component";
import {Router} from "@angular/router";

@Component({
  selector: 'adm-user-overview',
  templateUrl: './user-overview.component.html',
  styleUrls: ['./user-overview.component.scss']
})
export class UserOverviewComponent implements OnInit {

  @ViewChild(UserListComponent)
  public userList: UserListComponent;

  public users: UserTO[];

  constructor(
    private userService: UserManagementService,
    private ms: ModalService,
    private spinner: SpinnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.userService.loadAllUsers().subscribe((value: UserTO[]) => this.users = value);
  }

  public navigateToUserDetailPage(user: UserTO): void {
    this.router.navigateByUrl("user-management/edit/" + user.id.toString());
  }

  public openCreateUserModal(): void {
    this.ms.openCreateUserModal().afterClosed().subscribe(value => {
      if (ApplicationHelper.isObjectDefined(value)) {
        this.spinner.spin(true);
        this.userService.createUser(value).subscribe(res => {
          this.users.push(res);
          this.userList.updateTable();
          this.spinner.spin(false);
        }, () => this.spinner.spin(false));
      }
    });
  }

}
