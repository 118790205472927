<div *ngIf="preLogin else loggedIn">
  <router-outlet></router-outlet>
</div>

<ng-template #loggedIn>
  <div class="application-content">
    <mat-toolbar>
      <span class="headline">treeIT Admin</span>
      <button routerLink="/my-user/overview" mat-button>My User</button>
      <button *ngIf="isAdmin" routerLink="/user-management/overview" mat-button>Accounts</button>
      <button *ngIf="isAdmin" routerLink="/server-management/overview" mat-button>Applications</button>
      <button *ngIf="isAdmin" routerLink="/reports/logs" mat-button>Reports</button>
      <div class="spacer"></div>
      <div>{{fullUserName}}</div>
      <div (click)="logout()" class="logout">
        <span class="material-icons">directions_run</span>
      </div>
    </mat-toolbar>
    <mat-sidenav-container hasBackdrop="false">
      <mat-sidenav mode="side" [opened]="sideNavOpen">
        <adm-side-nav></adm-side-nav>
      </mat-sidenav>
      <mat-sidenav-content>
        <div class="route-content-container">
          <router-outlet></router-outlet>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </div>
</ng-template>
<til-spinner *ngIf="loading"></til-spinner>
