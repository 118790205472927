<div class="bg"></div>
<div class="init-container">
  <div class="login-inputs">
    <img class="ti-logo" src="../../../../assets/images/ti-logo.png"/>
    <form class="animated fadeIn" [formGroup]="initForm" (keyup.enter)="init()">
      <mat-form-field>
        <mat-label>Username</mat-label>
        <input matInput placeholder="Username" formControlName="userName" #fn="matInput">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password</mat-label>
        <input type="password" matInput placeholder="Password" formControlName="password">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Password Repeat</mat-label>
        <input type="password" matInput placeholder="Password" formControlName="passwordR">
      </mat-form-field>
      <div *ngIf="passwordMissmatch" class="animated fadeIn">
      <til-message icon="person" content="Passwords not matching..." headline="Please check"></til-message>
      </div>
      <div class="actions">
        <button [disabled]="initForm.invalid" (click)="init()" mat-raised-button color="accent">Init
          Application
        </button>
      </div>
    </form>
  </div>
</div>
<img class="init-image" src="../../../../assets/images/init.png"/>
