import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {AccountTO, AliasTO, DomainInformationTO, DomainTO} from "../../shared/generated/transportObjects";
import {UserManagementEndpoints} from "./user-management-endpoints";

@Injectable({
  providedIn: 'root'
})
export class MailManagementService {

  constructor(
    private http: HttpClient,
    @Inject('ADM_SERVER_URL') private adminServerUrl: string
  ) { }

  public findAllAccounts(): Observable<AccountTO[]> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.findAllAccounts();
    return this.http.get<AccountTO[]>(url);
  }

  public findAllDomains(): Observable<DomainTO[]> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.findAllDomains();
    return this.http.get<DomainTO[]>(url);
  }

  public findUserAccount(accountId: number): Observable<AccountTO> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.findAccountById(accountId);
    return this.http.get<AccountTO>(url);
  }

  public createAccount(account: AccountTO): Observable<AccountTO> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.createAccount();
    return this.http.post<AccountTO>(url, account);
  }

  public updateAccount(account: AccountTO): Observable<AccountTO> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.updateAccount();
    return this.http.put<AccountTO>(url, account);
  }

  public changeAccountPassword(accountId: number, password: string): Observable<void> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.changeAccountPassword(accountId);
    return this.http.put<void>(url, password);
  }

  public deleteAccount(accountId: number): Observable<any> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.deleteAccount(accountId);
    return this.http.delete(url);
  }

  public createMailbox(mailbox: AliasTO): Observable<AliasTO> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.createMailbox();
    return this.http.post<AliasTO>(url, mailbox);
  }

  public updateMailbox(mailbox: AliasTO): Observable<AliasTO> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.updateMailbox();
    return this.http.put<AliasTO>(url, mailbox);
  }

  public deleteMailbox(mailboxId: number): Observable<void> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.deleteMailbox(mailboxId);
    return this.http.delete<void>(url);
  }

  public deleteDomain(domainId: number): Observable<void> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.deleteDomain(domainId);
    return this.http.delete<void>(url);
  }

  public createDomain(domain: DomainTO): Observable<DomainTO> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.createDomain();
    return this.http.post<DomainTO>(url, domain);
  }

  public readDomainInformation(domainId: number): Observable<DomainInformationTO> {
    const url: string = this.adminServerUrl + UserManagementEndpoints.readDomainInformation(domainId);
    return this.http.get<DomainInformationTO>(url);
  }

}
