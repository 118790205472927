import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ReportRoutingModule} from './report-routing.module';
import {SharedModule} from "../shared/shared.module";
import {ApplicationLogsComponent} from './components/application-logs/application-logs.component';


@NgModule({
  declarations: [ApplicationLogsComponent],
  imports: [
    CommonModule,
    SharedModule,
    ReportRoutingModule
  ]
})
export class ReportModule { }
