import {Component, OnInit} from '@angular/core';
import {AuthServerService} from "../../services/auth-server.service";
import {ClientTO, ResourceServerTO, ScopeTO} from "../../../shared/generated/transportObjects";
import {HttpErrorResponse} from "@angular/common/http";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {ModalService} from "../../../core/services/modal.service";
import {AuthenticationService, SpinnerService} from "ti-frontend-shared";
import {InformationModalContext} from "../../../shared/model/information-modal-context";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {ApplicationRoles} from "../../../shared/util/application-roles";

@Component({
  selector: 'adm-auth-server-overview',
  templateUrl: './auth-server-overview.component.html',
  styleUrls: ['./auth-server-overview.component.scss']
})
export class AuthServerOverviewComponent implements OnInit {

  public clients: ClientTO[];
  public selectedClient: ClientTO;
  public grantedResourceServer: ResourceServerTO[];
  public grantedScopes: ScopeTO[];
  public authServerUnreachable: boolean = false;
  public selectingPermissions: boolean = false;
  public readonly su: boolean;

  constructor(
    private as: AuthServerService,
    private ms: ModalService,
    private spinner: SpinnerService,
    private authService: AuthenticationService
  ) {
    this.su = this.authService.isUserInRole(ApplicationRoles.SU);
  }

  ngOnInit(): void {
    this.as.readAllClients().subscribe(res => {
      this.clients = res;
      if (res.length > 0) {
        this.selectClient(this.clients[0])
      }
    }, (err: HttpErrorResponse) => {
      this.authServerUnreachable = err.status === 502;
    });
  }

  public openCreateEditClientModal(editClient?: ClientTO): void {
    this.ms.openCreateEditClientModal(editClient).afterClosed().subscribe(client => {
      if (ApplicationHelper.isObjectDefined(client)) {
        this.spinner.spin(true);
        if (ApplicationHelper.isObjectDefined(editClient)) {
          this.as.updateClient(client).subscribe(res => {
            this.updateClient(res);
            this.spinner.spin(false);
          }, (err: HttpErrorResponse) => {
            this.spinner.spin(false);
            if (err.status === 409) {
              this.openConflictModal();
            }
          });
        } else {
          this.as.createClient(client).subscribe(res => {
            this.clients.push(res);
            this.spinner.spin(false);
            this.openClientSecretInfoModal(res);
          }, (err: HttpErrorResponse) => {
            this.spinner.spin(false);
            if (err.status === 409) {
              this.openConflictModal();
            }
          });
        }
      }
    })
  }

  public openConflictModal(): void {
    const ctx: InformationModalContext = {
      headline: "Operation failed",
      content: "Could not create/update oauth client. Client ID is already taken.",
      severity: "warn"
    }
    this.ms.openInformationModal(ctx);
  }

  public clientSelected(client: ClientTO): boolean {
    return client.id === this.selectedClient.id;
  }

  public selectClient(client: ClientTO): void {
    this.selectedClient = client;
    this.grantedResourceServer = client.grantedResourceServers;
    this.grantedScopes = client.grantedScopes;
  }

  public filterGrantedPermissions(scopes: ScopeTO[]): ScopeTO[] {
    return scopes.filter(value => this.hasPermission(value));
  }

  public hasPermission(scope: ScopeTO): boolean {
    return ApplicationHelper.isObjectDefined(this.grantedScopes.find(value => value.id === scope.id));
  }

  public hasAnyPermission(): boolean {
    return this.selectedClient?.grantedResourceServers?.length > 0;
  }

  public checkDeleteClient(): void {
    const client: ClientTO = this.selectedClient;
    const ctx: ConfirmationModalContext = {
      headline: "Really delete client",
      content: `Do you really want to delete client ${client.clientName}?`,
      approveButtonLabel: "Delete",
      severity: "warn"
    };
    this.ms.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.deleteClient(client);
      }
    });
  }

  public updateClient(client: ClientTO) {
    this.clients = this.clients.filter(value => value.id !== client.id);
    this.clients.push(client);
    this.selectClient(client);
  }

  public checkResetSecret(): void {
    const client: ClientTO = this.selectedClient;
    this.ms.openResetClientSecretModal(client).afterClosed().subscribe(res => {
      if (res === undefined) {
        return;
      }
      if (res !== null) {
        this.resetClientSecret(client, res);
      } else {
        this.resetClientSecret(client, null);
      }
    });
  }

  private resetClientSecret(client: ClientTO, secret: string): void {
    this.spinner.spin(true);
    this.as.resetClientSecret(client.id, secret).subscribe((res) => {
      this.spinner.spin(false);
      this.openClientSecretInfoModal(res);
    }, () => this.spinner.spin(false));
  }

  private deleteClient(client: ClientTO): void {
    this.spinner.spin(true);
    this.as.deleteClient(client.id).subscribe(() => {
      this.clients = this.clients.filter(value => value.id !== client.id);
      this.selectClient(this.clients[0]);
      this.spinner.spin(false);
    }, () => this.spinner.spin(false))
  }

  private openClientSecretInfoModal(client: ClientTO) {
    const ctx: InformationModalContext = {
      headline: "Client Secret",
      content: [`This is the client secret for ${client.clientName}.`, "If you close this modal without saving the credentials - i promise - you will never see them again.", client.clientSecret],
      approveButtonLabel: "Understood - I know what I'm doing"
    }
    this.ms.openInformationModal(ctx);
  }


}
