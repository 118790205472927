import {Injectable} from "@angular/core";
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from "@angular/router";
import {Observable} from "rxjs";
import {AuthenticationService} from "ti-frontend-shared";
import {AuthorizationHelper} from "../../shared/util/authorization-helper";

@Injectable({
   providedIn: "root"
})
export class AdminGuard implements CanActivate {
   constructor(
     private router: Router,
     private authenticationService: AuthenticationService
   ) {}

   canActivate(
      next: ActivatedRouteSnapshot,
      state: RouterStateSnapshot
   ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      if (this.authenticationService.isAuthenticated() && AuthorizationHelper.isUserInRole(this.authenticationService.currentUser, "admin")) {
         return true;
      } else {
         this.router.navigateByUrl("/login");
         return false;
      }
   }
}
