import {Component, OnInit} from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";
import {ApplicationHelper, SpinnerService} from "ti-frontend-shared";
import {ValidationHelper} from "../../../shared/util/validation-helper";
import {MailManagementService} from "../../services/mail-management.service";
import {DomainTO} from "../../../shared/generated/transportObjects";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'adm-create-domain-modal',
  templateUrl: './create-domain-modal.component.html',
  styleUrls: ['./create-domain-modal.component.scss']
})
export class CreateDomainModalComponent implements OnInit {

  public domain: string;
  public domainPattern: string = ValidationHelper.domainPattern;
  public exists: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateDomainModalComponent, DomainTO>,
    private ms: MailManagementService,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
  }

  public validDomain(): boolean {
    return ApplicationHelper.isObjectDefined(this.domain) && this.domain.length > 0 && this.domain.match(this.domainPattern).length === 1;
  }

  public save(): void {
    if (this.validDomain()) {
      this.exists = false;
      this.spinner.spin(true);
      this.ms.createDomain({domain: this.domain}).subscribe(res => {
        this.spinner.spin(false);
        this.dialogRef.close(res);
      }, (err: HttpErrorResponse) => {
        this.spinner.spin(false);
        this.exists = true;
      });
    }
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }

}
