import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ClientTO} from "../../../shared/generated/transportObjects";

@Component({
  selector: 'adm-reset-client-secret-modal',
  templateUrl: './reset-client-secret-modal.component.html',
  styleUrls: ['./reset-client-secret-modal.component.scss']
})
export class ResetClientSecretModalComponent implements OnInit {

  client: ClientTO;
  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ResetClientSecretModalComponent, string>,
    @Inject(MAT_DIALOG_DATA) public data: ClientTO
  ) {
    this.client = this.data;
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  public save(): void {
    if (this.formGroup.controls.secret.valid) {
      this.dialogRef.close(this.formGroup.controls.secret.value);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      secret: this.fb.control(undefined),
    });
  }

}
