import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {User} from "../../shared/model/user";
import {ApiEndpoints} from "../../shared/util/api-endpoints";
import {AccountTO, UserTO} from "../../shared/generated/transportObjects";

@Injectable({
  providedIn: 'root'
})
export class MyUserService {

  constructor(
    private http: HttpClient,
    @Inject('ADM_SERVER_URL') private adminServerUrl: string
  ) { }

  public getUserInfo(): Observable<UserTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.userInfo;
    return this.http.get<UserTO>(url);
  }

  public changeUserPassword(oldPassword: string, newPassword: string): Observable<Object> {
    const url: string = this.adminServerUrl + ApiEndpoints.changePw;
    return this.http.post(url, { oldPassword: oldPassword, newPassword: newPassword });
  }

  public changeUserInfo(newFirstName: string, newLastName: string): Observable<UserTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.changeUserInfo;
    return this.http.post<UserTO>(url, { newFirstName: newFirstName, newLastName: newLastName });
  }

  public loadUserMailAccount(): Observable<AccountTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.userMailAccountInfo;
    return this.http.get<AccountTO>(url);
  }

}
