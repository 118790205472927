import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {UserManagementService} from "../../services/user-management.service";
import {UserTO, VerificationTokenTO} from "../../../shared/generated/transportObjects";
import {AuthenticationService, SpinnerService} from "ti-frontend-shared";
import {ModalService} from "../../../core/services/modal.service";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {AuthorizationHelper} from "../../../shared/util/authorization-helper";
import {ApplicationRoles} from "../../../shared/util/application-roles";

@Component({
  selector: 'adm-show-edit-user',
  templateUrl: './show-edit-user.component.html',
  styleUrls: ['./show-edit-user.component.scss']
})
export class ShowEditUserComponent implements OnInit {

  public user: UserTO;
  public token: VerificationTokenTO;
  public editMode: boolean = false;
  public copied: boolean = false;
  public readonly isSu: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private userManagementService: UserManagementService,
    private spinner: SpinnerService,
    private ms: ModalService,
    private router: Router,
    private as: AuthenticationService,
    @Inject('ADM_CLIENT_URL') private adminClientUrl: string
  ) {
    this.isSu = AuthorizationHelper.isUserInRole(this.as.currentUser, ApplicationRoles.SU);
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    const userId: number = this.activatedRoute.snapshot.params["userId"];
    this.userManagementService.loadUser(userId).subscribe(value => {
      this.user = value;
      this.spinner.spin(false)
    }, () => this.spinner.spin(false));
  }

  public createToken(): void {
    this.userManagementService.createAccountVerificationToken(this.user.userName).subscribe((res) => this.token = res);
  }

  public updateUserInfo(user: UserTO) {
    this.spinner.spin(true);
    this.userManagementService.updateUser(user).subscribe(res => {
      this.user = res;
      this.spinner.spin(false);
      this.editMode = false;
    }, () => this.spinner.spin(false));
  }

  public checkDeleteUser(): void {
    const ctx: ConfirmationModalContext = {
      headline: "Delete user " + this.user.userName,
      content: "Do you really want to delete the user? This action cannot be undone.",
      approveButtonLabel: "delete"
    }
    this.ms.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.deleteUser();
      }
    })
  }

  public canDelete(): boolean {
    return this.isSu || !this.user.roles.includes(ApplicationRoles.SU);
  }

  private deleteUser(): void {
    this.userManagementService.deleteUser(this.user.userName).subscribe(() => this.router.navigateByUrl("/user-management/overview"));
  }

  public copyActivationLink(): void {
    if (this.token) {
      this.copied = true;
      setTimeout(() => {
        this.copied = false
      }, 1500);
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = this.adminClientUrl + "/my-user/verify/" + this.token.token;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }
  }

}
