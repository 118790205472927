import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {RoleTO} from "../../../shared/generated/transportObjects";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {UserManagementService} from "../../services/user-management.service";
import {SpinnerService} from "ti-frontend-shared";

@Component({
  selector: 'adm-create-edit-role-modal',
  templateUrl: './create-edit-role-modal.component.html',
  styleUrls: ['./create-edit-role-modal.component.scss']
})
export class CreateEditRoleModalComponent implements OnInit {

  public formGroup: FormGroup;
  public editMode: boolean;

  constructor(
    public dialogRef: MatDialogRef<CreateEditRoleModalComponent, RoleTO>,
    @Inject(MAT_DIALOG_DATA) public data: RoleTO,
    private fb: FormBuilder,
    private us: UserManagementService,
    private spinner: SpinnerService
  ) {
    this.editMode = ApplicationHelper.isObjectDefined(this.data);
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  public saveRole(): void {
    if (this.formGroup.valid) {
    this.spinner.spin(true);
      if (this.editMode) {
        this.us.updateRole(this.formGroup.value).subscribe(res => {
          this.dialogRef.close(res);
          this.spinner.spin(false);
        }, () => this.spinner.spin(false));
      } else {
        this.us.createRole(this.formGroup.value).subscribe(res => {
          this.dialogRef.close(res);
          this.spinner.spin(false);
        }, () => this.spinner.spin(false));
      }
    }
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(""),
      version: this.fb.control(""),
      roleName: this.fb.control("", Validators.required),
      roleDescription: this.fb.control("")
    });
    if (ApplicationHelper.isObjectDefined(this.data)) {
      this.formGroup.patchValue(this.data);
    }
  }

}
