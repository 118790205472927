import {Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from "@angular/material/dialog";
import {CreateUserModalComponent} from "../../user-management/modals/create-user-modal/create-user-modal.component";
import {AccountTO, AliasTO, ClientTO, DomainTO, ResourceServerTO, RoleTO, ScopeTO, UserTO} from "../../shared/generated/transportObjects";
import {ConfirmationModalComponent} from "../../shared/modals/confirmation-modal/confirmation-modal.component";
import {ConfirmationModalContext} from "../../shared/model/confirmation-modal-context";
import {CreateEditRoleModalComponent} from "../../user-management/modals/create-edit-role-modal/create-edit-role-modal.component";
import {AddResourceServerModalComponent} from "../../auth-server/modals/add-resource-server-modal/add-resource-server-modal.component";
import {EditCreateScopeModalComponent} from "../../auth-server/modals/edit-create-scope-modal/edit-create-scope-modal.component";
import {EditCreateAuthClientModalComponent} from "../../auth-server/modals/edit-create-auth-client-modal/edit-create-auth-client-modal.component";
import {InformationModalContext} from "../../shared/model/information-modal-context";
import {InformationModalComponent} from "../../shared/modals/information-modal/information-modal.component";
import {CreateEditMailAccountModalComponent} from "../../user-management/modals/create-edit-mail-account-modal/create-edit-mail-account-modal.component";
import {CreateEditMailboxModalComponent} from "../../user-management/modals/create-edit-mailbox-modal/create-edit-mailbox-modal.component";
import {ResetClientSecretModalComponent} from "../../auth-server/modals/reset-client-secret-modal/reset-client-secret-modal.component";
import {CreateDomainModalComponent} from "../../user-management/modals/create-domain-modal/create-domain-modal.component";
import {SetMailAccountPasswordModalComponent} from "../../shared/modals/set-mail-account-password-modal/set-mail-account-password-modal.component";

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor(public dialog: MatDialog) {}

  private readonly small: string = "480px";
  private readonly medium: string = "680px";
  private readonly big: string = "980px";

  public openCreateUserModal(): MatDialogRef<CreateUserModalComponent, UserTO> {
    return this.dialog.open(CreateUserModalComponent, {
      width: this.small
    });
  }

  public openConfirmationModal(context: ConfirmationModalContext): MatDialogRef<ConfirmationModalComponent, boolean> {
    return this.dialog.open(ConfirmationModalComponent, {
      width: this.small,
      data: context
    });
  }

  public openInformationModal(context: InformationModalContext): MatDialogRef<InformationModalComponent, void> {
    return this.dialog.open(InformationModalComponent, {
      width: this.small,
      data: context
    });
  }

  public openCreateEditRoleModal(role?: RoleTO): MatDialogRef<CreateEditRoleModalComponent, RoleTO> {
    return this.dialog.open(CreateEditRoleModalComponent, {
      width: this.small,
      data: role
    });
  }

  public openCreateResourceServerModal(edit?: ResourceServerTO): MatDialogRef<AddResourceServerModalComponent, ResourceServerTO> {
    return this.dialog.open(AddResourceServerModalComponent, {
      width: this.medium,
      data: edit
    });
  }

  public openCreateEditScopeModal(scope?: ScopeTO): MatDialogRef<EditCreateScopeModalComponent, ScopeTO> {
    return this.dialog.open(EditCreateScopeModalComponent, {
      width: this.small,
      data: scope
    });
  }

  public openCreateEditClientModal(client?: ClientTO): MatDialogRef<EditCreateAuthClientModalComponent, ClientTO> {
    return this.dialog.open(EditCreateAuthClientModalComponent, {
      width: this.small,
      data: client
    });
  }

  public openCreateEditAccountModal(account?: AccountTO): MatDialogRef<CreateEditMailAccountModalComponent, AccountTO> {
    return this.dialog.open(CreateEditMailAccountModalComponent, {
      width: this.small,
      data: account
    });
  }

  public openCreateEditMailboxModal(account: AccountTO, mailbox?: AliasTO): MatDialogRef<CreateEditMailboxModalComponent, AliasTO> {
    return this.dialog.open(CreateEditMailboxModalComponent, {
      width: this.small,
      data: {account: account, mailbox: mailbox}
    });
  }

  public openResetClientSecretModal(client: ClientTO): MatDialogRef<ResetClientSecretModalComponent, string> {
    return this.dialog.open(ResetClientSecretModalComponent, {
      width: this.small,
      data: client
    });
  }

  public openAddDomainModal(): MatDialogRef<CreateDomainModalComponent, DomainTO> {
    return this.dialog.open(CreateDomainModalComponent, {
      width: this.small
    });
  }

  public openSetMailAccountPasswordModal(account: string): MatDialogRef<SetMailAccountPasswordModalComponent, string> {
    return this.dialog.open(SetMailAccountPasswordModalComponent, {
      data: account,
      width: this.small
    });
  }

}
