import {Component, Inject, OnInit} from '@angular/core';

@Component({
  selector: 'adm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(
    @Inject('ADM_SERVER_URL') private adminServerUrl: string
  ) { }

  ngOnInit(): void {
  }

  public get admUrl(): string {
    return this.adminServerUrl;
  }

}
