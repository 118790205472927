import {Component, Input, OnInit} from '@angular/core';
import {AccountTO, AliasTO} from "../../generated/transportObjects";
import {AuthenticationService, SpinnerService} from "ti-frontend-shared";
import {MailManagementService} from "../../../user-management/services/mail-management.service";
import {ModalService} from "../../../core/services/modal.service";
import {ConfirmationModalContext} from "../../model/confirmation-modal-context";
import {Router} from "@angular/router";
import {ApplicationHelper} from "../../util/application-helper";

@Component({
  selector: 'adm-show-mail-account',
  templateUrl: './show-mail-account.component.html',
  styleUrls: ['./show-mail-account.component.scss']
})
export class ShowMailAccountComponent implements OnInit {

  @Input()
  public account: AccountTO;

  @Input()
  private administrationMode: boolean = false;
  private readonly isAdmin: boolean;
  public readonly isSu: boolean;

  constructor(
    private authService: AuthenticationService,
    private mailService: MailManagementService,
    private modalService: ModalService,
    private spinner: SpinnerService,
    private router: Router
  ) {
    this.isAdmin = this.authService.isUserInRole("ADMIN");
    this.isSu = this.authService.isUserInRole("SU");
  }

  ngOnInit(): void {
    this.account.mailboxes = this.sortMailboxes(this.account.mailboxes);
  }

  public openEditModal(): void {
    this.modalService.openCreateEditAccountModal(this.account).afterClosed().subscribe(res => {
      if (res) {
        this.account = res;
        this.account.mailboxes = this.sortMailboxes(this.account.mailboxes);
      }
    });
  }

  public openSetPasswordModal(): void {
    this.modalService.openSetMailAccountPasswordModal(this.account.username + "@" + this.account.domain).afterClosed().subscribe(res => {
      if (res) {
        this.setAccountPassword(res);
      }
    });
  }

  public openCheckDeleteMailboxModal(mailbox: AliasTO): void {
    const ctx: ConfirmationModalContext = {
      severity: "warn",
      approveButtonLabel: "delete mailbox",
      headline: "Really delete?",
      content: "Do you really want to delete alias " + mailbox.sourceUsername + "?"
    }
    this.modalService.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.spinner.spin(true);
        this.mailService.deleteMailbox(mailbox.id).subscribe(() => {
          this.account.mailboxes = this.account.mailboxes.filter(value => value.id !== mailbox.id);
          this.spinner.spin(false);
        }, () => this.spinner.spin(false))
      }
    })
  }

  public openMailboxModal(mailbox?: AliasTO): void {
    this.modalService.openCreateEditMailboxModal(this.account, mailbox).afterClosed().subscribe(res => {
      if (res) {
        if (!ApplicationHelper.isObjectDefined(this.account.mailboxes)) {
          this.account.mailboxes = [];
        }
        if (ApplicationHelper.isObjectDefined(mailbox)) {
          // update
          this.account.mailboxes = this.account.mailboxes.filter(value => value.id !== mailbox.id);
        }
        this.account.mailboxes.push(res);
        this.account.mailboxes = this.sortMailboxes(this.account.mailboxes);
      }
    });
  }

  public get adminMode(): boolean {
    return this.isAdmin && this.administrationMode;
  }

  public checkDelete(): void {
    const ctx: ConfirmationModalContext = {
      severity: "warn",
      approveButtonLabel: "delete",
      headline: "Delete mail account " + this.account.username + "@" + this.account.domain + "?",
      content: "Do your really want to delete the mail account?"
    }
    this.modalService.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.deleteAccount();
      }
    })
  }

  private deleteAccount(): void {
    this.spinner.spin(true);
    this.mailService.deleteAccount(this.account.id).subscribe(res => {
      this.spinner.spin(false);
      this.router.navigateByUrl("/user-management/mails");
    }, () => this.spinner.spin(false));
  }

  private sortMailboxes(mailboxes: AliasTO[]): AliasTO[] {
    return mailboxes.sort((a, b) => a.sourceUsername > b.sourceUsername ? 1 : -1)
  }

  private setAccountPassword(password: string): void {
    this.spinner.spin(true);
    this.mailService.changeAccountPassword(this.account.id, password).subscribe(() => {}, () => {}, () => this.spinner.spin(false));
  }

}
