<form class="animated fadeIn" [formGroup]="formGroup" (keyup.enter)="changeInfo()">
  <div class="change-info-container">
    <mat-form-field>
      <mat-label>Firstname</mat-label>
      <input matInput placeholder="Firstname" formControlName="firstName" #fn="matInput">
    </mat-form-field>
    <mat-form-field>
      <mat-label>Lastname</mat-label>
      <input matInput placeholder="Lastname" formControlName="lastName">
    </mat-form-field>
    <div *ngIf="errorChangingInfo" class="animated fadeIn">
      <til-message icon="offline_bolt" content="Error changing user info"
                   headline="User information could not be changed."></til-message>
    </div>
    <div class="ti actions">
      <button (click)="cancel.emit()" mat-button color="warn">cancel</button>
      <button [disabled]="formGroup.invalid" (click)="changeInfo()" mat-raised-button
              color="accent">
        Change
      </button>
    </div>
  </div>
</form>
