<div class="server-overview">
  <div class="header">
    <h1>Resource Server</h1>
    <div class="flex-spacer"></div>
    <div *ngIf="!authServerUnreachable && server" class="add-server-button animate__animated animate__fadeIn">
      <button (click)="addOrEditServer()" mat-fab>
        <mat-icon>add</mat-icon>
      </button>
    </div>
  </div>


  <div class="server-container">

    <div class="scrollable server-list">

      <adm-inline-spinner *ngIf="!server && !authServerUnreachable"></adm-inline-spinner>
      <til-flex-icon-message *ngIf="authServerUnreachable" color="accent" icon="cloud_off">Server not reachable</til-flex-icon-message>

      <div *ngIf="server">
        <div (click)="selected = s" *ngFor="let s of server" [class.selected]="serverSelected(s)" class="server-item">
          {{s.serverName}} ({{s.serverIdentifier}})
        </div>
      </div>

    </div>

    <div class="scrollable server-details">
      <adm-inline-spinner *ngIf="!server && !authServerUnreachable"></adm-inline-spinner>
      <til-flex-icon-message *ngIf="authServerUnreachable" color="accent" icon="cloud_off">Server not reachable</til-flex-icon-message>
      <div *ngIf="selected" class="animate__animated animate__fadeIn">
        <div class="server-info">
          <div class="details-header">
            <h2>{{selected.serverName}}</h2>
            <div class="flex-spacer"></div>
            <span *ngIf="isSu" (click)="addOrEditServer(selected)" class="material-icons">edit</span>
          </div>
          <div>
            Server Identifier: {{selected.serverIdentifier}}
          </div>

          <div>
            <i>{{selected.serverDescription}}</i>
          </div>
        </div>

        <div class="scopes">
          <h3>{{selected.scopes?.length > 0 ? "Scopes:" : "- no scopes -"}}</h3>
          <div class="scope" *ngFor="let scope of selected.scopes">
            <div>
              <div>{{scope.scope}}</div>
              <div>{{scope.scopeDescription}}</div>
            </div>
            <div class="flex-spacer"></div>
            <div *ngIf="isSu" class="scope-controls">
              <button color="warn" (click)="checkRemoveScope(scope)" mat-mini-fab>
                <mat-icon>remove</mat-icon>
              </button>
              <button (click)="addOrEditScope(scope)" mat-mini-fab>
                <mat-icon>edit</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="selected" class="flex-spacer"></div>
      <div *ngIf="selected" class="actions">
        <button *ngIf="isSu" (click)="checkRemoveServer()" mat-button color="warn">Delete Server</button>
        <div class="flex-spacer"></div>
        <button (click)="addOrEditScope()" mat-flat-button color="accent">Add Scope</button>
      </div>
    </div>

  </div>


</div>
