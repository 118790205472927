import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiEndpoints} from "../../shared/util/api-endpoints";
import {ActivateUserTO} from "../../shared/generated/transportObjects";

@Injectable({
  providedIn: 'root'
})
export class UserTokenService {

  constructor(
    private http: HttpClient,
    @Inject('ADM_SERVER_URL') private adminServerUrl: string
  ) { }

  public verifyToken(token: string): Observable<ActivateUserTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.verifyToken;
    return this.http.put<ActivateUserTO>(url, token);
  }

  public activateUserAccount(activateUser: ActivateUserTO): Observable<any> {
    const url: string = this.adminServerUrl + ApiEndpoints.activateUser;
    return this.http.put(url, activateUser);
  }



}
