import {AuthenticatedUser} from "ti-frontend-shared";
import {ApplicationHelper} from "./application-helper";

export class AuthorizationHelper {

  public static isUserInRole(user: AuthenticatedUser, role: string): boolean {
    return ApplicationHelper.isObjectDefined(user?.roles) ? user.roles.includes(role.toUpperCase()) : false;
  }

}
