import {AfterViewInit, Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {ApplicationHelper} from "../../shared/util/application-helper";
import {UserTokenService} from "../services/user-token.service";
import {AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators} from "@angular/forms";
import {ActivateUserTO} from "../../shared/generated/transportObjects";

@Component({
  selector: 'adm-verify-user-account',
  templateUrl: './verify-user-account.component.html',
  styleUrls: ['./verify-user-account.component.scss']
})
export class VerifyUserAccountComponent implements OnInit, AfterViewInit {

  public verifying: boolean = true;
  public user: ActivateUserTO;
  public success: boolean = false;

  public formGroup: FormGroup;

  constructor(
    private activatedRoute: ActivatedRoute,
    private tokenService: UserTokenService,
    private fb: FormBuilder
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  ngAfterViewInit() {
    const token: string = this.activatedRoute.snapshot.params["token"];
    if (ApplicationHelper.isObjectDefined(token)) {
      this.tokenService.verifyToken(token).subscribe((res) => this.user = res, () => this.showFailure());
    } else {
      this.showFailure();
    }
  }

  public activateUser(): void {
    if (this.formGroup.valid) {
      this.user.password = this.formGroup.get("password").value;
      this.tokenService.activateUserAccount(this.user).subscribe(() => this.showSuccess(), () => this.showFailure());
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      password: this.fb.control("", Validators.required),
      passwordR: this.fb.control("", Validators.required)
    }, {
      validator: this.passwordValidator("password", "passwordR")
    });
  }

  private showSuccess(): void {
    this.verifying = false;
    this.success = true;
  }

  private showFailure(): void {
    this.verifying = false;
    this.success = false;
  }

  private passwordValidator(name1: string, name2: string): ValidatorFn {
    return (ac: AbstractControl) => {
      const group: FormGroup = ac as FormGroup;
      if (group.get(name1).value === group.get(name2).value) {
        return null;
      } else {
        return {'pwNotEqual': 'Passwords did not match'};
      }
    };
  }

}
