<div class="role-overview-container">

  <div class="headline">

  <h1>System Roles</h1>
  <div class="flex-spacer"></div>
  <div (click)="openCreateRoleModal()" class="add-user-button">
    <button mat-fab><mat-icon>add</mat-icon></button>
  </div>
  </div>

  <div *ngIf="tableDataSource" class="roles-table">
    <table mat-table [dataSource]="tableDataSource">
      <mat-text-column name="roleName" headerText="Role"></mat-text-column>
      <mat-text-column name="roleDescription" headerText="Description"></mat-text-column>
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td class="action-column" mat-cell *matCellDef="let role">
          <button *ngIf="isSu" mat-button [matMenuTriggerFor]="menu">
            <mat-icon>edit</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="select(role)" mat-menu-item>Edit</button>
            <button (click)="openConfirmDeleteModal(role)" mat-menu-item>Delete</button>
          </mat-menu>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
      <tr mat-row *matRowDef="let role; columns: visibleColumns"></tr>
    </table>
  </div>

</div>
