<adm-sub-navigation [routeAction]="false" (click)="cancel()">cancel</adm-sub-navigation>
<div class="select-permission-container">
<div class="header">
  <h1>Choose permissions for {{client.clientName}}</h1>
  <div class="flex-spacer"></div>
  <button (click)="save()" mat-fab>
    <mat-icon>done_all</mat-icon>
  </button>
</div>
  <div *ngIf="availableResourceServer" class="server-list">
    <div *ngFor="let s of availableResourceServer; let i = index" [ngStyle]="{animationDelay: i * 100 + 'ms'}" [class.granted]="hasServerPermission(s)" class="server animate__animated animate__backInUp">
      <div class="server-header">
      <h3>{{s.serverName}}</h3>
        <div class="flex-spacer"></div>
        <mat-checkbox [checked]="hasServerPermission(s)" (change)="grantOrRevokeServerPermission(s)"></mat-checkbox>
      </div>
      <p>Identifier: {{s.serverIdentifier}}</p>
      <p><i>{{s.serverDescription}}</i></p>
      <div class="scopes">
        <mat-chip-list>
          <mat-chip [matTooltip]="scope.scopeDescription" [ngStyle]="{cursor: hasServerPermission(s) ? 'pointer' : 'not-allowed'}" (click)="grantOrRevokeScope(s, scope)" [disabled]="!hasServerPermission(s)" [color]="hasScopePermission(scope) ? 'accent': 'none'" selected *ngFor="let scope of s.scopes">{{scope.scope}}</mat-chip>
        </mat-chip-list>
      </div>
    </div>
  </div>
  <div *ngIf="!availableResourceServer" class="loading-container">
    <adm-inline-spinner></adm-inline-spinner>
  </div>
</div>
