<div class="verify-user-container">

  <div *ngIf="verifying && !user" class="animated fadeIn verification-loading">
    <div class="icon-bar">
      <span class="material-icons">self_improvement</span>
      <span class="material-icons">face</span>
      <span class="material-icons">how_to_reg</span>
    </div>
    <div class="info">verifying user...</div>
  </div>

  <div *ngIf="verifying && user">

    <form [formGroup]="formGroup">

      <div class="animated fadeIn verification-user-input">
        <div class="verification-text">
          <h1>Hello {{user.firstName}},</h1>
          <p>please setup your user account by providing a password.</p>
        </div>
        <div>
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input tiAutoFocus type="password" matInput placeholder="Password" formControlName="password">
          </mat-form-field>
          <mat-form-field>
            <mat-label>Password Repeat</mat-label>
            <input type="password" matInput placeholder="Password (Repeat)" formControlName="passwordR">
          </mat-form-field>
        </div>
        <div class="ti actions">
          <button [disabled]="formGroup.invalid" (click)="activateUser()" mat-raised-button
                  color="accent">
            Activate User
          </button>
        </div>
      </div>
    </form>

  </div>

  <div *ngIf="!verifying && success" class="animated fadeIn verification-successful">
    <div><span class="material-icons">fingerprint</span></div>
    <div>Verification successful!</div>
    <div>Your user account is verified. Please <a routerLink="/login">log in</a> to manage your user account.</div>
  </div>

  <div *ngIf="!verifying && !success" class="animated fadeIn verification-failed">
    <div><span class="material-icons">fingerprint</span></div>
    <div>Verification failed</div>
    <div>Your user account could not be verified. If the problem persists please consult the issuer of the link.</div>
  </div>

</div>
