import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {SpinnerService} from "ti-frontend-shared";
import {MyUserService} from "../../services/my-user.service";
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'adm-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  @Output()
  public passwordChanged: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public cancel: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("fn", {static: true})
  private fn: MatInput;

  public passwordMismatch: boolean = false;
  public errorChangingPassword: boolean = false;
  private processing: boolean;
  public formGroup: FormGroup;

  constructor(
    private fb: FormBuilder,
    private spinner: SpinnerService,
    private userService: MyUserService
  ) { }

  ngOnInit(): void {
    this.initFormGroup();
    setTimeout(() => {
      this.fn.focus();
    }, 500);
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      oldPassword: this.fb.control("", Validators.required),
      newPassword: this.fb.control("", Validators.required),
      newPasswordR: this.fb.control("", Validators.required)
    });
  }

  public changePassword(): void {
    if (this.formGroup.valid && !this.processing) {
      this.processing = true;
      this.passwordMismatch = false;
      this.errorChangingPassword = false;
      let newPassword = this.formGroup.get("newPassword").value;
      if (newPassword !== this.formGroup.get("newPasswordR").value) {
        this.passwordMismatch = true;
        this.processing = false;
      } else {
      this.spinner.spin(true);
        this.userService.changeUserPassword(this.formGroup.get("oldPassword").value, newPassword).subscribe(
          () => {
            this.processing = false;
            this.spinner.spin(false);
            this.passwordChanged.emit();
          }, () => {
            this.processing = false;
            this.spinner.spin(false);
            this.errorChangingPassword = true;
          }
        )
      }
    }
  }

}
