import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {ClientTO, ResourceServerTO, ScopeTO} from "../../shared/generated/transportObjects";
import {ApiEndpoints} from "../../shared/util/api-endpoints";

@Injectable({
  providedIn: 'root'
})
export class AuthServerService {

  constructor(
    private http: HttpClient,
    @Inject('ADM_SERVER_URL') private adminServerUrl: string
  ) { }

  public readAllResourceServer(): Observable<ResourceServerTO[]> {
    const url: string = this.adminServerUrl + ApiEndpoints.readAllResourceServer;
    return this.http.get<ResourceServerTO[]>(url);
  }

  public readResourceServer(resourceServerId: number): Observable<ResourceServerTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.readResourceServer(resourceServerId);
    return this.http.get<ResourceServerTO>(url);
  }

  public createResourceServer(server: ResourceServerTO): Observable<ResourceServerTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.createResourceServer;
    return this.http.post<ResourceServerTO>(url, server);
  }

  public updateResourceServer(server: ResourceServerTO): Observable<ResourceServerTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.updateResourceServer;
    return this.http.put<ResourceServerTO>(url, server);
  }

  public deleteResourceServer(resourceServerId: number): Observable<ResourceServerTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.deleteResourceServer(resourceServerId);
    return this.http.delete<ResourceServerTO>(url);
  }

  public readAllClients(): Observable<ClientTO[]> {
    const url: string = this.adminServerUrl + ApiEndpoints.readAllClients;
    return this.http.get<ClientTO[]>(url);
  }

  public readClient(clientId: number): Observable<ClientTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.readClient(clientId);
    return this.http.get<ClientTO>(url);
  }

  public createClient(client: ClientTO): Observable<ClientTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.createClient;
    return this.http.post<ClientTO>(url, client);
  }

  public updateClient(client: ClientTO): Observable<ClientTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.updateClient;
    return this.http.put<ClientTO>(url, client);
  }

  public updateClientPermissions(client: ClientTO): Observable<ClientTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.updateClientPermissions;
    return this.http.put<ClientTO>(url, client);
  }

  public resetClientSecret(clientId: number, secret?: string): Observable<ClientTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.resetClientSecret(clientId);
    if(secret) {
    let params: HttpParams = new HttpParams();
    params = params.append('secret', secret);
    return this.http.post<ClientTO>(url, {}, {params});
    } else {
      return this.http.post<ClientTO>(url,{});
    }
  }

  public deleteClient(clientId: number): Observable<ClientTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.deleteClient(clientId);
    return this.http.delete<ClientTO>(url);
  }

  public createScope(resourceServerId: number, scope: ScopeTO): Observable<ScopeTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.createScope(resourceServerId);
    return this.http.post<ScopeTO>(url, scope);
  }

  public updateScope(scope: ScopeTO): Observable<ScopeTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.updateScope;
    return this.http.put<ScopeTO>(url, scope);
  }

  public deleteScope(scopeId: number): Observable<ScopeTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.deleteScope(scopeId);
    return this.http.delete<ScopeTO>(url);
  }

}
