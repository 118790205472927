<div *ngIf="account" class="show-account-container">

  <div class="account-header">
    <h1>{{account.username}}@{{account.domain}}</h1>
    <div class="flex-spacer"></div>
    <span *ngIf="account.sendonly" matTooltip="sendonly account" class="material-icons">forward_to_inbox</span>
    <div matTooltip="Mailbox size" class="quota-batch">{{account.quota}} MB</div>
    <button *ngIf="adminMode" [matMenuTriggerFor]="editAccountMenu" mat-fab color="accent"><mat-icon>edit</mat-icon></button>
    <mat-menu #editAccountMenu="matMenu">
      <button (click)="openEditModal()" mat-menu-item><mat-icon>edit</mat-icon>Edit Account</button>
      <button (click)="openMailboxModal()" mat-menu-item><mat-icon>add</mat-icon>Add Mailbox</button>
      <button (click)="openSetPasswordModal()" mat-menu-item><mat-icon>lock</mat-icon>Set Password</button>
    </mat-menu>
    <button *ngIf="adminMode && isSu" (click)="checkDelete()" mat-fab color="warn"><mat-icon>remove</mat-icon></button>
  </div>

  <div *ngIf="account.mailboxes && account.mailboxes.length > 0; else nomb" class="aliases animate__animated animate__fadeIn">
    <div *ngFor="let alias of account.mailboxes; let i = index" class="alias">
      <div *ngIf="adminMode" class="edit"><span [matMenuTriggerFor]="editAliasMenu" class="material-icons">edit</span></div>
      <mat-menu #editAliasMenu="matMenu">
        <button (click)="openMailboxModal(alias)" mat-menu-item><mat-icon>edit</mat-icon>Edit Mailbox</button>
        <button (click)="openCheckDeleteMailboxModal(alias)" class="delete-button" mat-menu-item><mat-icon>remove</mat-icon>Delete Mailbox</button>
      </mat-menu>
      <div class="status"><span class="material-icons">{{alias.enabled ? 'check' : 'remove'}}</span> {{alias.enabled ? 'Enabled' : 'Disabled'}}</div>
      <div class="alias-name">{{alias.sourceUsername}}</div>
      <div class="alias-domain">@{{alias.sourceDomain}}</div>
    </div>
  </div>

  <ng-template #nomb>
    <div class="no-aliases">
      <til-flex-icon-message color="accent" icon="font_download_off">
        No aliases created
      </til-flex-icon-message>
    </div>
  </ng-template>

</div>
