export class ApiEndpoints {
  // base
  public static base = "/treeit/admin"

  // init
  private static initBase = `${ApiEndpoints.base}/init`
  public static isServerInitialized = `${ApiEndpoints.initBase}/initialized`
  public static initSu = `${ApiEndpoints.initBase}/create-su`
  // my user endpoints
  private static userBase = `${ApiEndpoints.base}/user`
  public static userInfo = `${ApiEndpoints.userBase}/info`;
  public static changePw = `${ApiEndpoints.userBase}/change-pw`;
  public static changeUserInfo = `${ApiEndpoints.userBase}/change-user-info`;
  public static userMailAccountInfo = `${ApiEndpoints.userBase}/mail/account`;
  // user admin endpoints
  private static adminUserBase = `${ApiEndpoints.base}/user-administration`
  public static adminGetAllUsers = `${ApiEndpoints.adminUserBase}/all`;
  public static adminGetAvailableRoles = `${ApiEndpoints.adminUserBase}/roles/all`;
  public static adminCreateUser = `${ApiEndpoints.adminUserBase}/create`;
  public static adminUpdateUser = `${ApiEndpoints.adminUserBase}/update`;
  public static adminAllRoles = `${ApiEndpoints.adminUserBase}/roles/all`
  public static adminUpdateRole = `${ApiEndpoints.adminUserBase}/roles/update`
  public static adminCreateRole = `${ApiEndpoints.adminUserBase}/roles/create`
  public static adminDeleteRole(roleId: number): string {
    return `${ApiEndpoints.adminUserBase}/roles/delete/${roleId.toString()}`
  };
  public static adminDeleteUser(userName: string): string {
    return `${ApiEndpoints.adminUserBase}/delete/${userName}`
  };
  public static adminLoadUser(userId: number): string {
    return `${ApiEndpoints.adminUserBase}/read/${userId.toString()}`
  };
  public static createUserToken(userName: string): string {
    return `${ApiEndpoints.adminUserBase}/token/${userName}`
  };
  // token endpoints
  private static tokenBase = `${ApiEndpoints.base}/token`
  public static verifyToken = `${ApiEndpoints.tokenBase}/verify`
  public static activateUser = `${ApiEndpoints.tokenBase}/activate-user`

  // auth server management
  private static authServerManagementBase = `${ApiEndpoints.base}/auth-server-administration`
  public static readAllResourceServer = `${ApiEndpoints.authServerManagementBase}/resource-server/all`
  public static createResourceServer = `${ApiEndpoints.authServerManagementBase}/resource-server/create`
  public static updateResourceServer = `${ApiEndpoints.authServerManagementBase}/resource-server/update`
  public static readResourceServer(resourceServerId: number): string {
    return `${ApiEndpoints.authServerManagementBase}/resource-server/read/${resourceServerId}`
  };
  public static deleteResourceServer(resourceServerId: number): string {
    return `${ApiEndpoints.authServerManagementBase}/resource-server/delete/${resourceServerId}`
  };
  public static readAllClients = `${ApiEndpoints.authServerManagementBase}/clients/all`
  public static createClient = `${ApiEndpoints.authServerManagementBase}/clients/create`
  public static updateClient = `${ApiEndpoints.authServerManagementBase}/clients/update`
  public static updateClientPermissions = `${ApiEndpoints.authServerManagementBase}/clients/update-permissions`

  public static resetClientSecret(clientId: number): string {
    return `${ApiEndpoints.authServerManagementBase}/clients/reset-secret/${clientId}`
  };
  public static readClient(clientId: number): string {
    return `${ApiEndpoints.authServerManagementBase}/clients/read/${clientId}`
  };
  public static deleteClient(clientId: number): string {
    return `${ApiEndpoints.authServerManagementBase}/clients/delete/${clientId}`
  };
  public static updateScope = `${ApiEndpoints.authServerManagementBase}/scopes/update`
  public static deleteScope(scopeId: number): string {
    return `${ApiEndpoints.authServerManagementBase}/scopes/delete/${scopeId}`
  };
  public static createScope(resourceServerId: number): string {
    return `${ApiEndpoints.authServerManagementBase}/scopes/create/${resourceServerId}`
  };

}
