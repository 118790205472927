import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserDataOverviewComponent} from "./user-data-overview/user-data-overview.component";
import {VerifyUserAccountComponent} from "./verify-user-account/verify-user-account.component";
import {TiUserGuard} from "../core/guards/ti-user.guard";
import {MyUserMailAccountsComponent} from "./my-user-mail-accounts/my-user-mail-accounts.component";

const basePath: string = "my-user/";
const routes: Routes = [
  {
    path: basePath + "overview",
    component: UserDataOverviewComponent,
    canActivate: [TiUserGuard]
  },
  {
    path: basePath + "verify/:token",
    component: VerifyUserAccountComponent
  },
  {
    path: basePath + "mail-accounts",
    component: MyUserMailAccountsComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MyUserRoutingModule {
}
