<div *ngIf="accounts && accounts.length > 0;" class="mail-accounts-container">

  <div class="ti admin-header">
  <h1>All Mail Accounts</h1>
    <div class="flex-spacer"></div>
    <div class="actions">
      <button (click)="openCreateAccountModal()" mat-fab><mat-icon>add</mat-icon></button>
    </div>

  </div>

  <div class="account-list">
    <table mat-table [dataSource]="tableDataSource">
      <ng-container matColumnDef="userName">
        <th mat-header-cell *matHeaderCellDef>Username</th>
        <td mat-cell *matCellDef="let account">
          <span class="user-name">{{ account.username }}@{{account.domain}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="quota">
        <th mat-header-cell *matHeaderCellDef>Quota</th>
        <td mat-cell *matCellDef="let account">
          <span>{{ account.quota }} MB</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="sendOnly">
        <th mat-header-cell *matHeaderCellDef>Send Only</th>
        <td mat-cell *matCellDef="let account">
          <span *ngIf="account.sendonly"><span class="material-icons">check</span></span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="aliases">
        <th mat-header-cell *matHeaderCellDef>Aliases</th>
        <td mat-cell *matCellDef="let account">
          <span>{{account.mailboxes?.length || 0}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <ng-container matColumnDef="enabled">
        <th mat-header-cell *matHeaderCellDef>Enabled</th>
        <td mat-cell *matCellDef="let account">
          <span [ngClass]="!account.enabled ? 'warning' : ''" class="material-icons">{{account.enabled ? 'check' : 'remove'}}</span>
        </td>
        <td mat-footer-cell *matFooterCellDef></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="visibleColumns"></tr>
      <tr class="ti selectable-row" [routerLink]="'account/' + account.id.toString()" mat-row *matRowDef="let account; columns: visibleColumns"></tr>
    </table>
  </div>

</div>


  <div *ngIf="accounts && accounts.length === 0 && !serverNotReachable" class="no-aliases">
    <til-flex-icon-message color="accent" icon="font_download_off">
      No Accounts created
    </til-flex-icon-message>
  </div>
    <til-flex-icon-message *ngIf="serverNotReachable" color="accent" icon="cloud_off">Server not reachable</til-flex-icon-message>
