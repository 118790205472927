import { Component, OnInit } from '@angular/core';
import {MyUserService} from "../services/my-user.service";
import {SpinnerService} from "ti-frontend-shared";
import {User} from "../../shared/model/user";
import {UserTO} from "../../shared/generated/transportObjects";

@Component({
  selector: 'adm-user-data-overview',
  templateUrl: './user-data-overview.component.html',
  styleUrls: ['./user-data-overview.component.scss']
})
export class UserDataOverviewComponent implements OnInit {

  public user: UserTO;
  public changingPassword: boolean;
  public changingUserInfo: boolean;

  constructor(
    private userService: MyUserService,
    private spinnerService: SpinnerService
  ) {
    this.spinnerService.spin(true);
  }

  ngOnInit(): void {
    this.userService.getUserInfo().subscribe(
      (user: UserTO) => {
        this.user = user;
        this.spinnerService.spin(false);
      }, () => this.spinnerService.spin(false)
    );
  }

}
