<h2>{{editMode ? "Edit system role" : "Create system role"}}</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
  <div>
    <mat-form-field>
      <mat-label>Role Name*</mat-label>
      <input #roleName matInput formControlName="roleName" name="roleName" type="text" maxlength="30" />
      <mat-hint align="end">{{ roleName.value?.length || 0 }}/30</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <mat-label>Role Description*</mat-label>
      <textarea #roleDescription matInput formControlName="roleDescription" name="roleDescription" type="text" maxlength="255"></textarea>
      <mat-hint align="end">{{ roleDescription.value?.length || 0 }}/255</mat-hint>
    </mat-form-field>
  </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="cancel()" mat-button>cancel</button>
  <button color="primary" (click)="saveRole()" mat-raised-button>save</button>
</mat-dialog-actions>
