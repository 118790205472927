import {enableProdMode,} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {environment} from './environments/environment';
import {APP_CONFIG, AppConfig} from './app.config';
import {AppModule} from './app/app.module';

fetch('/assets/context/context.json')
.then((response) => response.json())
.then((config: AppConfig) => {
  if (environment.production) {
    enableProdMode();
  }

  platformBrowserDynamic([{provide: APP_CONFIG, useValue: config}, {
    provide: 'ADM_SERVER_URL',
    useValue: config.adminServerUrl
  }, {provide: 'ADM_CLIENT_URL', useValue: config.adminClientUrl}])
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
});
