<form class="animated fadeIn" [formGroup]="formGroup" (keyup.enter)="changePassword()">
  <div class="change-pw-container">
    <mat-form-field>
      <mat-label>Old Password</mat-label>
      <input type="password" matInput placeholder="Old Password" formControlName="oldPassword" #fn="matInput">
    </mat-form-field>
    <mat-form-field>
      <mat-label>New Password</mat-label>
      <input type="password" matInput placeholder="New Password" formControlName="newPassword">
    </mat-form-field>
    <mat-form-field>
      <mat-label>New Password Repeat</mat-label>
      <input type="password" matInput placeholder="New Password" formControlName="newPasswordR">
    </mat-form-field>
    <div *ngIf="passwordMismatch" class="animated fadeIn">
      <til-message icon="report_problem" content="Passwords not matching..."
                   headline="Please check"></til-message>
    </div>
    <div *ngIf="errorChangingPassword" class="animated fadeIn">
      <til-message icon="offline_bolt" content="Error changing password"
                   headline="Password could not be changed."></til-message>
    </div>
    <div class="ti actions">
      <button (click)="cancel.emit()" mat-button color="warn">cancel</button>
      <button [disabled]="formGroup.invalid" (click)="changePassword()" mat-raised-button
              color="accent">
        Change Password
      </button>
    </div>
  </div>
</form>
