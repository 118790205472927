import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminGuard} from "../core/guards/admin.guard";
import {UserOverviewComponent} from "./components/user-overview/user-overview.component";
import {ShowEditUserComponent} from "./components/show-edit-user/show-edit-user.component";
import {RolesOverviewComponent} from "./components/roles-overview/roles-overview.component";
import {MailAccountsOverviewComponent} from "./components/mail-accounts-overview/mail-accounts-overview.component";
import {AdministrateMailAccountComponent} from "./components/administrate-mail-account/administrate-mail-account.component";
import {DomainManagementComponent} from "./components/domain-management/domain-management.component";
import {ShowDomainComponent} from "./components/show-domain/show-domain.component";


const basePath: string = "user-management/";
const routes: Routes = [
  { path: basePath,
    redirectTo: basePath + "overview" },
  {
    path: basePath + "overview",
    component: UserOverviewComponent,
    canActivate: [AdminGuard]
  },
  {
    path: basePath + "edit/:userId",
    component: ShowEditUserComponent,
    canActivate: [AdminGuard]
  },
  {
    path: basePath + "roles",
    component: RolesOverviewComponent,
    canActivate: [AdminGuard]
  },
  {
    path: basePath + "mails",
    component: MailAccountsOverviewComponent,
    canActivate: [AdminGuard]
  },
  {
    path: basePath + "mails/account/:accountId",
    component: AdministrateMailAccountComponent,
    canActivate: [AdminGuard]
  },
  {
    path: basePath + "domains",
    component: DomainManagementComponent,
    canActivate: [AdminGuard]
  },
  {
    path: basePath + "domain/:domainId",
    component: ShowDomainComponent,
    canActivate: [AdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserManagementRoutingModule { }
