<h2>Create OAuth Client</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Client Name*</mat-label>
        <input #client matInput formControlName="clientName" name="clientName" type="text" maxlength="100"/>
        <mat-hint align="end">{{ client.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Client ID*</mat-label>
        <input #cid matInput formControlName="clientId" name="clientId" type="text" maxlength="100"/>
        <mat-hint align="end">{{ cid.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Token validity in seconds*</mat-label>
        <input #vis matInput formControlName="validityInSeconds" name="validityInSeconds" type="text" maxlength="100"/>
        <mat-hint align="end">{{ vis.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
    </div>
  </form>
  <div class="ti actions">
    <button mat-button (click)="dialogRef.close(undefined)">Cancel</button>
    <button [disabled]="formGroup.invalid" (click)="save()" mat-flat-button color="accent">Save</button>
  </div>
</mat-dialog-content>

