import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {MailManagementService} from "../../services/mail-management.service";
import {DomainInformationTO} from "../../../shared/generated/transportObjects";
import {SpinnerService} from "ti-frontend-shared";

@Component({
  selector: 'adm-show-domain',
  templateUrl: './show-domain.component.html',
  styleUrls: ['./show-domain.component.scss']
})
export class ShowDomainComponent implements OnInit {

  public domainInformation: DomainInformationTO;

  constructor(
    private activatedRoute: ActivatedRoute,
    private mailService: MailManagementService,
    private spinner: SpinnerService
  ) {
    this.spinner.spin(true);
  }

  ngOnInit(): void {
    this.mailService.readDomainInformation(this.activatedRoute.snapshot.params["domainId"]).subscribe(res => {
      this.domainInformation = res;
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }


}
