<adm-sub-navigation target="/user-management/overview">overview</adm-sub-navigation>

<div *ngIf="user && !editMode" class="show-edit-user-container">
  <div class="header">
    <h1>{{user.firstName}} {{user.lastName}} ({{user.userName}})</h1>
    <div class="flex-spacer"></div>
    <button (click)="editMode = true" mat-fab>
      <mat-icon>edit</mat-icon>
    </button>
  </div>
  <div class="user-details">
    <div class="left">
      <div>Emp. No.:</div>
      <div>Verification state:</div>
      <div *ngIf="token">Token:</div>
    </div>
    <div class="right">
      <div>{{user.empNr || 'not assigned'}}</div>
      <div>{{(user.verified | date) || 'not verified'}} <span *ngIf="!user.verified"> - <a (click)="createToken()">create token</a></span></div>
      <div *ngIf="token">Token created and valid until {{token.validUntil | date}} - <a *ngIf="!copied" (click)="copyActivationLink()">copy activation
        link</a><span *ngIf="copied" class="copy-check positive material-icons">check</span></div>
    </div>
  </div>
  <div class="user-roles">
    <mat-chip-list>
      <mat-chip color="accent" *ngFor="let role of user.roles">{{role}}</mat-chip>
    </mat-chip-list>
  </div>
  <div *ngIf="canDelete()" class="delete-user">
    <a (click)="checkDeleteUser()" class="ti warning link">delete</a>
  </div>
</div>

<div *ngIf="editMode && user" class="edit-user-container">

  <div>
    <adm-edit-user-info (saved)="updateUserInfo($event)" (cancel)="editMode = false" [edit]="user"></adm-edit-user-info>
  </div>


</div>
