import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {WelcomeComponent} from "./core/components/welcome/welcome.component";
import {InitializationComponent} from "./core/components/initialization/initialization.component";
import {LoginComponent} from "./core/components/login/login.component";
import {TiUserGuard} from "./core/guards/ti-user.guard";


const routes: Routes = [
  { path: "",
    redirectTo: "welcome",
    pathMatch: "full" },
  {
    path: "welcome",
    component: WelcomeComponent,
    canActivate: [TiUserGuard]
  },
  {
    path: "application/init",
    component: InitializationComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
