import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {UserTO} from "../../generated/transportObjects";
import {ApplicationHelper} from "../../util/application-helper";
import {UserManagementService} from "../../../user-management/services/user-management.service";

@Component({
  selector: 'adm-edit-user-info',
  templateUrl: './edit-user-info.component.html',
  styleUrls: ['./edit-user-info.component.scss']
})
export class EditUserInfoComponent implements OnInit {

  public formGroup: FormGroup;

  public availableRoles: string[] = [];

  @Input()
  public edit: UserTO;

  @Output()
  public cancel: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  public saved: EventEmitter<UserTO> = new EventEmitter<UserTO>();

  constructor(
    private fb: FormBuilder,
    private us: UserManagementService
  ) { }

  ngOnInit(): void {
    this.us.loadAvailableRoles().subscribe(value => this.availableRoles = value.map(r => r.roleName));
    this.initFormGroup();
    if (ApplicationHelper.isObjectDefined(this.edit)) {
      this.formGroup.patchValue(this.edit);
    }
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(""),
      version: this.fb.control(""),
      userName: this.fb.control("", Validators.required),
      firstName: this.fb.control("", Validators.required),
      lastName: this.fb.control("", Validators.required),
      roles: this.fb.control(undefined)
    });
  }

  public get valid(): boolean {
    return this.formGroup.valid;
  }

  public save(): void {
    if (this.valid) {
      this.saved.emit(this.formGroup.value);
    }
  }

}
