<h2>{{editMode ? "Edit Mailbox" : "Create Mailbox"}}</h2>
<mat-dialog-content>
  <form novalidate [formGroup]="formGroup">
    <div>
      <mat-form-field>
        <mat-label>Alias*</mat-label>
        <input #sourceUsername matInput formControlName="sourceUsername" name="sourceUsername" type="text" maxlength="100"/>
        <mat-hint align="end">{{ sourceUsername.value?.length || 0 }}/100</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Domain*</mat-label>
        <mat-select formControlName="sourceDomain" name="sourceDomain">
          <mat-option *ngFor="let d of domains" [value]="d">
            {{ d }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div>
        <mat-checkbox color="primary" formControlName="enabled" sendonly="quota">Enabled</mat-checkbox>
      </div>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="dialogRef.close(undefined)" mat-button>cancel</button>
  <button [disabled]="formGroup.invalid" color="primary" (click)="save()" mat-raised-button>save</button>
</mat-dialog-actions>
