<div class="domains">

  <div class="header">
    <h1>Domainmanagement</h1>
    <div class="flex-spacer"></div>
    <button (click)="openAddDomainModal()" mat-fab color="accent"><mat-icon>add</mat-icon></button>
  </div>

  <div *ngFor="let d of domains" class="domain">
    <span (click)="openCheckDeleteModal(d)" class="material-icons">remove</span>
    <h3 [routerLink]="'/user-management/domain/' + d.id">{{d.domain}}</h3>
  </div>
</div>
