import {Inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {RoleTO, UserTO, VerificationTokenTO} from "../../shared/generated/transportObjects";
import {ApiEndpoints} from "../../shared/util/api-endpoints";

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  constructor(
    private http: HttpClient,
    @Inject('ADM_SERVER_URL') private adminServerUrl: string
  ) { }

  public loadAllUsers(): Observable<UserTO[]> {
    const url: string = this.adminServerUrl + ApiEndpoints.adminGetAllUsers;
    return this.http.get<UserTO[]>(url);
  }

  public loadUser(userId: number): Observable<UserTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.adminLoadUser(userId);
    return this.http.get<UserTO>(url);
  }

  public loadAvailableRoles(): Observable<RoleTO[]> {
    const url: string = this.adminServerUrl + ApiEndpoints.adminGetAvailableRoles;
    return this.http.get<RoleTO[]>(url);
  }

  public updateUser(user: UserTO) {
    const url: string = this.adminServerUrl + ApiEndpoints.adminUpdateUser;
    return this.http.put<UserTO>(url, user);
  }

  public createUser(user: UserTO) {
    const url: string = this.adminServerUrl + ApiEndpoints.adminCreateUser;
    return this.http.post<UserTO>(url, user);
  }

  public deleteUser(userName: string): Observable<any> {
    const url: string = this.adminServerUrl + ApiEndpoints.adminDeleteUser(userName);
    return this.http.delete(url);
  }

  public createAccountVerificationToken(userName: string): Observable<VerificationTokenTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.createUserToken(userName);
    return this.http.put<VerificationTokenTO>(url, {});
  }

  public deleteRole(roleId: number): Observable<any> {
    const url: string = this.adminServerUrl + ApiEndpoints.adminDeleteRole(roleId);
    return this.http.delete(url);
  }

  public updateRole(role: RoleTO): Observable<RoleTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.adminUpdateRole;
    return this.http.put<RoleTO>(url, role);
  }

  public createRole(role: RoleTO): Observable<RoleTO> {
    const url: string = this.adminServerUrl + ApiEndpoints.adminCreateRole;
    return this.http.post<RoleTO>(url, role);
  }

}
