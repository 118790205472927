import {Component, OnInit} from '@angular/core';
import {AuthServerService} from "../../services/auth-server.service";
import {ResourceServerTO, ScopeTO} from "../../../shared/generated/transportObjects";
import {ModalService} from "../../../core/services/modal.service";
import {AuthenticationService, SpinnerService} from "ti-frontend-shared";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {ConfirmationModalContext} from "../../../shared/model/confirmation-modal-context";
import {HttpErrorResponse} from "@angular/common/http";
import {InformationModalContext} from "../../../shared/model/information-modal-context";
import {AuthorizationHelper} from "../../../shared/util/authorization-helper";
import {ApplicationRoles} from "../../../shared/util/application-roles";

@Component({
  selector: 'adm-resource-server-management',
  templateUrl: './resource-server-management.component.html',
  styleUrls: ['./resource-server-management.component.scss']
})
export class ResourceServerManagementComponent implements OnInit {

  public server: ResourceServerTO[];
  public selected: ResourceServerTO;
  public authServerUnreachable: boolean = false;
  public readonly isSu: boolean;

  constructor(
    private as: AuthServerService,
    private authenticationService: AuthenticationService,
    private ms: ModalService,
    private spinner: SpinnerService
  ) {
    this.isSu = AuthorizationHelper.isUserInRole(this.authenticationService.currentUser, ApplicationRoles.SU);
  }

  ngOnInit(): void {
    this.as.readAllResourceServer().subscribe(res => {
      this.server = res;
      if (this.server.length > 0) {
        this.selected = this.server[0];
      }
    }, (err: HttpErrorResponse) => {
      this.authServerUnreachable = err.status === 502;
    });
  }

  public serverSelected(server: ResourceServerTO): boolean {
    return server.id === this.selected.id;
  }

  public addOrEditServer(edit?: ResourceServerTO): void {
    this.ms.openCreateResourceServerModal(edit).afterClosed().subscribe(res => {
      if (res) {
        this.spinner.spin(true);
        if (ApplicationHelper.isObjectDefined(edit)) {
          this.as.updateResourceServer(res).subscribe(res => {
            this.server = this.server.filter(value => value.id !== res.id);
            this.server.push(res);
            this.selected = res;
            this.spinner.spin(false);
          }, (err: HttpErrorResponse) => {
            this.spinner.spin(false);
            if (err.status === 409) {
              this.openConflictModal();
            }
          });
        } else {
        this.as.createResourceServer(res).subscribe(res => {
          this.server.push(res);
          this.selected = res;
          this.spinner.spin(false);
        }, (err: HttpErrorResponse) => {
          this.spinner.spin(false);
          if (err.status === 409) {
            this.openConflictModal();
          }
        });
        }
      }
    });
  }

  public openConflictModal(): void {
    const ctx: InformationModalContext = {
      headline: "Operation failed",
      content: "Could not create/update resource server. Server ID is already taken.",
      severity: "warn"
    }
    this.ms.openInformationModal(ctx);
  }

  public checkRemoveServer(): void {
    const ctx: ConfirmationModalContext = {
      headline: "Really delete server?",
      content: "Do you really want to delete resource server " + this.selected.serverName + "?",
      approveButtonLabel: "delete"
    }
    this.ms.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.removeServer();
      }
    })
  }

  private removeServer(): void {
    this.spinner.spin(true);
    this.as.deleteResourceServer(this.selected.id).subscribe(() => {
      this.server = this.server.filter(value => value.id !== this.selected.id);
      this.selected = this.server[0];
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }

  public checkRemoveScope(scope: ScopeTO): void {
    const ctx: ConfirmationModalContext = {
      headline: "Really delete scope?",
      content: "Do you really want to delete scope " + scope.scope + "?",
      approveButtonLabel: "delete"
    }
    this.ms.openConfirmationModal(ctx).afterClosed().subscribe(res => {
      if (res) {
        this.removeScope(scope);
      }
    })
  }

  private removeScope(scope: ScopeTO): void {
    this.spinner.spin(true);
    this.as.deleteScope(scope.id).subscribe(() => {
      this.selected.scopes = this.selected.scopes.filter(value => value.id !== scope.id);
      this.spinner.spin(false);
    }, () => this.spinner.spin(false));
  }

  public addOrEditScope(scope?: ScopeTO) {
    this.ms.openCreateEditScopeModal(scope).afterClosed().subscribe(res => {
      if (res) {
        this.spinner.spin(true);
        if (ApplicationHelper.isObjectDefined(scope)) {
          this.as.updateScope(res).subscribe(res => {
            this.selected.scopes = this.selected.scopes.filter(value => value.id !== res.id);
            this.selected.scopes.push(res);
            this.spinner.spin(false);
          }, () => this.spinner.spin(false));
        } else {
          this.as.createScope(this.selected.id, res).subscribe(res => {
            this.selected.scopes.push(res);
            this.spinner.spin(false);
          }, () => this.spinner.spin(false));
        }
      }
    })
  }

}
