import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthServerOverviewComponent} from "./components/auth-server-overview/auth-server-overview.component";
import {AdminGuard} from "../core/guards/admin.guard";
import {ResourceServerManagementComponent} from "./components/resource-server-management/resource-server-management.component";


const basePath: string = "server-management/";
const routes: Routes = [
  {
    path: basePath + "overview",
    component: AuthServerOverviewComponent,
    canActivate: [AdminGuard]
  },
  {
    path: basePath + "server",
    component: ResourceServerManagementComponent,
    canActivate: [AdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthServerRoutingModule { }
