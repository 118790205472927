<div class="user-overview-container">

  <div *ngIf="user" class="user-info">
    <div *ngIf="!changingPassword && !changingUserInfo" class="animated fadeIn show-container">
      <span class="material-icons">person_pin</span>
      <div class="user-name">{{user.userName}}</div>
      <div class="full-name">{{user.firstName}} {{user.lastName}}</div>
      <div class="user-roles">
        <div>Roles: [{{user.roles}}]</div>
      </div>
      <button mat-fab [matMenuTriggerFor]="appMenu">
        <mat-icon>edit</mat-icon>
      </button>
      <mat-menu #appMenu="matMenu" xPosition="after">
        <button (click)="changingUserInfo = true" mat-menu-item>
          <mat-icon>person_pin</mat-icon>
          Change User Info
        </button>
        <button (click)="changingPassword = true" mat-menu-item>
          <mat-icon>not_listed_location</mat-icon>
          Change Password
        </button>
      </mat-menu>
    </div>
    <div class="edit-container">
      <adm-change-password (cancel)="changingPassword = false"
                           (passwordChanged)="changingPassword = false"
                           *ngIf="changingPassword"></adm-change-password>
      <adm-change-user-info (cancel)="changingUserInfo = false"
                            (userInfoChanged)="changingUserInfo = false; user = $event" *ngIf="changingUserInfo"
                            [userInfo]="{ firstName: user.firstName, lastName: user.lastName }"></adm-change-user-info>
    </div>
  </div>

</div>
