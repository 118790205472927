<h2>{{data.headline}}</h2>
<mat-dialog-content>
  <div>
    <p *ngFor="let c of modalContent">{{c}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button (click)="dialogRef.close(false)" mat-button>{{data.declineButtonLabel || "cancel"}}</button>
  <button [color]="data.severity || 'warn'" (click)="dialogRef.close(true)" mat-raised-button>{{data.approveButtonLabel || "approve"}}</button>
</mat-dialog-actions>


