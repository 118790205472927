import {ApiEndpoints} from "../../shared/util/api-endpoints";

export class UserManagementEndpoints {
  /*
  Mails
   */
  public static mailBase = `${ApiEndpoints.base}/mails`
  public static findUserAccount(userName: string) {
    return `${UserManagementEndpoints.mailBase}/account/${userName}`
  }
  public static findAllAccounts() {
    return `${UserManagementEndpoints.mailBase}/accounts/all`
  }

  public static changeAccountPassword(accountId: number) {
    return `${UserManagementEndpoints.mailBase}/change-password/${accountId.toString()}`
  }

  public static findAllDomains() {
    return `${UserManagementEndpoints.mailBase}/domains/all`
  }

  public static findAccountById(accountId: number) {
    return `${UserManagementEndpoints.mailBase}/account/id/${accountId.toString()}`
  }

  public static createAccount() {
    return `${UserManagementEndpoints.mailBase}/accounts/create`
  }

  public static updateAccount() {
    return `${UserManagementEndpoints.mailBase}/accounts/update`
  }

  public static deleteAccount(accountId: number) {
    return `${UserManagementEndpoints.mailBase}/accounts/delete/${accountId.toString()}`
  }

  public static createMailbox() {
    return `${UserManagementEndpoints.mailBase}/mailbox/create`
  }

  public static updateMailbox() {
    return `${UserManagementEndpoints.mailBase}/mailbox/update`
  }

  public static deleteMailbox(mailboxId: number) {
    return `${UserManagementEndpoints.mailBase}/mailbox/delete/${mailboxId.toString()}`
  }

  public static deleteDomain(domainId: number) {
    return `${UserManagementEndpoints.mailBase}/domains/delete/${domainId.toString()}`
  }

  public static createDomain() {
    return `${UserManagementEndpoints.mailBase}/domains/create`
  }

  public static readDomainInformation(domainId: number) {
    return `${UserManagementEndpoints.mailBase}/domain/${domainId.toString()}`
  }


}
