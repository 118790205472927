import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AdminGuard} from "../core/guards/admin.guard";
import {ApplicationLogsComponent} from "./components/application-logs/application-logs.component";


const basePath: string = "reports/";
const routes: Routes = [
  { path: basePath,
    redirectTo: basePath + "logs" },
  {
    path: basePath + "logs",
    component: ApplicationLogsComponent,
    canActivate: [AdminGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ReportRoutingModule { }
