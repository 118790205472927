import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {AccountTO} from "../../../shared/generated/transportObjects";
import {SpinnerService} from "ti-frontend-shared";
import {MailManagementService} from "../../services/mail-management.service";
import {ApplicationHelper} from "../../../shared/util/application-helper";
import {HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'adm-create-edit-mail-account-modal',
  templateUrl: './create-edit-mail-account-modal.component.html',
  styleUrls: ['./create-edit-mail-account-modal.component.scss']
})
export class CreateEditMailAccountModalComponent implements OnInit {

  public formGroup: FormGroup;
  public readonly editMode: boolean;
  public domains: string[] = [];
  public accountExists: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<CreateEditMailAccountModalComponent, AccountTO>,
    @Inject(MAT_DIALOG_DATA) public data: AccountTO,
    private fb: FormBuilder,
    private ms: MailManagementService,
    private spinner: SpinnerService
  ) {
    this.editMode = ApplicationHelper.isObjectDefined(this.data);
  }

  ngOnInit(): void {
    this.ms.findAllDomains().subscribe(res => res.map(d => d.domain).forEach(d => this.domains.push(d)));
    this.initFormGroup();
  }

  public cancel(): void {
    this.dialogRef.close(undefined);
  }

  public save(): void {
    this.accountExists = false;
    if (this.formGroup.valid) {
      this.spinner.spin(true);
      if (this.editMode) {
        this.ms.updateAccount(this.formGroup.value).subscribe(res => {
          this.dialogRef.close(res);
          this.spinner.spin(false);
        }, (err) => this.handleError(err));
      } else {
        this.ms.createAccount(this.formGroup.value).subscribe(res => {
          this.dialogRef.close(res);
          this.spinner.spin(false);
        }, (err) => this.handleError(err));
      }
    }
  }

  private handleError(error: HttpErrorResponse): void {
    if (error.status === 409) {
      this.accountExists = true;
    }
    this.spinner.spin(false);
  }

  private initFormGroup(): void {
    this.formGroup = this.fb.group({
      id: this.fb.control(""),
      version: this.fb.control(""),
      username: this.fb.control("", Validators.required),
      domain: this.fb.control("", Validators.required),
      quota: this.fb.control("", Validators.required),
      sendonly: this.fb.control(false, Validators.required),
      enabled: this.fb.control(true, Validators.required)
    });
    if (ApplicationHelper.isObjectDefined(this.data)) {
      this.formGroup.patchValue(this.data);
    }
  }

}
