import {BrowserModule} from '@angular/platform-browser';
import {LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CoreModule} from "./core/core.module";
import {SharedModule} from "./shared/shared.module";
import {AuthServerModule} from "./auth-server/auth-server.module";
import {MyUserModule} from "./my-user/my-user.module";
import {UserManagementModule} from "./user-management/user-management.module";
import {ReportModule} from "./report/report.module";
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    AuthServerModule,
    MyUserModule,
    UserManagementModule,
    ReportModule
  ],
  providers: [{provide: LOCALE_ID, useValue: "de"}],
  bootstrap: [AppComponent]
})
export class AppModule { }
